import { API_ROUTES } from "../config";
import { http } from "../http"



export const loginService = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES.login, body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }

}


export const tokenValidate = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES.token, body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }
}


export const profileUpdateService = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES["profile-update"], body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }
}


export const changePasswordService = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES["change_password"], body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }
}



export const forgotPasswordService = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES["forgot-password"], body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }


}
export const resetPasswordService = async (body) => {
    try {
        const responseData = await http().post(API_ROUTES["reset-password"], body);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;
    }

}