import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export default function TaskHistory({ taskId, showHistory, setShowHistory }) {

    const [taskHistory, setTaskHistory] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            const getTaskHistory = await dispatch.projectDetails.getTaskHistory({ taskId })
            setTaskHistory(getTaskHistory.result)
        }
        fetchData()
    }, [])
    const updatedFields = taskHistory[0]?.updated_fields;
    const changedDate = taskHistory[0]?.createdAt;

    return (
        <div
            className={`fixed bottom-0 flex flex-col max-w-full bg-white  bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96 z-50`}>
            <div className="flex items-center justify-between p-4 pl-6">
                <h5 className="mb-0 leading-normal font-semibold">
                    Task History
                </h5>
                <button type="button"
                    className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    onClick={() => setShowHistory(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <hr className="w-full" />

            <div className="container mx-auto w-full h-full mb-4">
                <div className="p-4 pl-6 overflow-y-auto">
                    <label className="text-sm font-semibold">Previous Tasks</label>
                </div>
                {
                    taskHistory.length !== 0 ?
                        <div className="border-2-2 absolute ml-10 border-dashed pt-2 border-blue-500 h-full border"></div>
                        : null
                }
                <div className="relative wrap overflow-y-auto pl-10 pt-4 pr-10 h-full">
                    <div className="w-full mb-24">
                        {
                            taskHistory.length === 0 ?
                                <p className='text-center'>No Task History</p>
                                :
                                taskHistory.map((item, index) => {
                                    const updatedFields = item.updated_fields;
                                    return (
                                        updatedFields.map((field, fieldIndex) => {
                                            const key = Object.keys(field)[0];
                                            const value = field[key];
                                            return (
                                                <div className='flex'>
                                                    <div className="z-20 pl-1">
                                                        <div className="my-4 rounded-full h-10 w-10 flex items-center bg-indigo-300 ring-4 ring-indigo-400 ring-opacity-30">
                                                        </div>
                                                    </div>
                                                    <div key={index} className="bg-gray-300 mb-10 rounded-lg shadow-xl ml-2 w-full text-sm px-6 py-4">
                                                        <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
                                                            {key} was changed on {moment(changedDate).format('YYYY-MM-DD')}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }))
                                }
                                )
                        }



                    </div>
                </div>
            </div>

        </div>
    );
}

