
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Overview from '../../../components/ProjectDetails/Overview';
import Requirement from '../../../components/ProjectDetails/Requirement';
import Issues from '../../../components/ProjectDetails/Issues';
import Roadmap from '../../../components/ProjectDetails/Roadmap';
import Member from '../../../components/ProjectDetails/Member';
import MemberAvailability from '../../../components/ProjectDetails/MemberAvailability';
import Technologies from '../../../components/ProjectDetails/Technologies';
import { toast } from 'react-toastify';
import Loader from '../../../components/Utilities/Loader';
import ModalLayout from '../../../components/Common/ModalLayout';
import ProjectCreate from './ProjectCreate';
import ProjectFiles from '../../../components/ProjectDetails/ProjectDocuments'
import { useSelector, useDispatch } from 'react-redux';

const ProjectDetails = () => {

    const [projectStatus, setProjectStatus] = useState("")
    const [status, setStatus] = useState("overview")
    const [isLoading, setLoading] = useState(false);
    const [open, SetOpenModal] = useState(false);
    const [mobStatusView, setMobStatusView] = useState(false);
    const DetailedProjectData = useSelector(state => state.projectDetails.getDetailedProjectData)
    const dispatch = useDispatch()

    const tabTitle = [
        {
            id: '1',
            label: 'Overview',
            status: 'overview'
        },
        {
            id: '2',
            label: 'Requirement',
            status: 'requirement'

        },
        {
            id: '3',
            label: 'Technologies',
            status: 'technologies'
        },
        {
            id: '6',
            label: 'Files',
            status: 'files'
        },
        {
            id: '7',
            label: 'Member',
            status: 'member'
        }
    ]

    const params = useParams();
    const projectname = DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.name;

    const handleModal = () => {
        SetOpenModal(!open);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (params.name) {
                try {
                    await dispatch.projectDetails.getProjectDetailes({ projectName: params.name });
                } catch (err) {
                    console.log("err", err)
                }
            }
        }
        fetchData()
    }, [params.name]);

    useEffect(() => {
        if (DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.status) {
            setProjectStatus(DetailedProjectData.project.status);
        }
    }, [DetailedProjectData])

    useEffect(() => {
        return () => {
            dispatch.projectDetails.updateProjectDetailsData({});
        }
    }, [])

    const tabDetail = (DetailedProjectData) => {
        switch (status) {
            case "overview":
                return (<Overview DetailedProjectData={DetailedProjectData} />)
            case "requirement":
                return <Requirement DetailedProjectData={DetailedProjectData} />
            case "technologies":
                return <Technologies DetailedProjectData={DetailedProjectData} />
            case "issues":
                return <Issues data={DetailedProjectData} />
            case "roadmap":
                return <Roadmap DetailedProjectData={DetailedProjectData} />
            case "files":
                return <ProjectFiles DetailedProjectData={DetailedProjectData} />
            case "member":
                return <Member data={DetailedProjectData} />
            case "memberavailability":
                return <MemberAvailability data={DetailedProjectData} />
            default:
                return <Overview DetailedProjectData={DetailedProjectData} />
        }
    }

    const updateProjectStatus = async () => {
        try {
            setLoading(true);
            const responseData = await dispatch.projectDetails.projectStatusChange({ project_id: DetailedProjectData.project._id, status: projectStatus });
            if (responseData && responseData.success) {
                toast.success("Project status updated");
            } else {
                toast.error(responseData.message);
            }
            setLoading(false);

        } catch (err) {
            setLoading(false);
            toast.error(err.error);
        }
    }

    const servertechnologies = DetailedProjectData && DetailedProjectData.project &&
        DetailedProjectData.project.technologies &&
        DetailedProjectData.project.technologies.server.length > 0 &&
        DetailedProjectData.project.technologies.server.map((servertech, serverind) => {
            return (<span key={serverind} className="border text-tiny items-center p-2 px-3 font-medium mr-1  mb-1 bg-white rounded text-secondary-grey-200 inline-block">{servertech && servertech.techId && servertech.techId.name}</span>)
        });

    const clienttechnologies = DetailedProjectData && DetailedProjectData.project &&
        DetailedProjectData.project.technologies &&
        DetailedProjectData.project.technologies.client.length > 0 &&
        DetailedProjectData.project.technologies.client.map((clienttech, clientind) => {
            return (<span key={clientind} className="border text-tiny items-center p-2 px-3 font-medium mr-1 mb-1 bg-white rounded text-secondary-grey-200 inline-block">{clienttech && clienttech.techId && clienttech.techId.name}</span>)
        });

    return (
        <>
            <div className="">
                <div className="flex flex-col lg:flex-row">
                    <div className={`bg-white rounded mb-5 lg:mb-0  ${!['files', 'requirement', 'technologies'].includes(status) ? "md:w-[calc(100%_-_320px)]  w-full " : "md:w-full  w-full "} `}>
                        <div className="pt-4">
                            <h3 className="font-title text-xl text-black text-title font-bold pb-3 px-6">{projectname}</h3>

                            <header className="px-6 border-b md:flex">
                                {tabTitle && tabTitle.map((item) =>
                                    <button key={item.id} onClick={() => setStatus(item.status)} className={` ${item.status === status ? "text-secondary-blue-400 border-primary-navyBlue" : "border-transparent text-gray-500"}  border-b mr-3 sm:mr-8 font-title text-xs md:text-sm  pb-3 text-title font-normal`} >
                                        {item.label}
                                    </button>
                                )}
                            </header>

                            <div className={` ${status === "requirement" ? "py-0 " : "px-6 py-2 md:py-7"}`}>
                                {DetailedProjectData ? tabDetail(DetailedProjectData) : null}
                            </div>
                        </div>

                    </div>

                    {!['files', 'requirement', 'technologies'].includes(status) ?
                        <>
                            <div className="hidden md:flex flex-col m-6 mt-0 md:m-0 md:w-72 lg:w-80">

                                <div className="p-7 py-24 right-0 top-0 h-full fixed md:w-72 lg:w-80 bg-secondary-grey-100">
                                    <div className="">
                                        <div className="text-tiny font-semibold text-black pb-2">Status</div>
                                        <div className="flex">
                                            <div className="relative">
                                                <select
                                                    onChange={(evt) => { setProjectStatus(evt.target.value) }}
                                                    value={projectStatus}
                                                    className="form-select form-select-lg appearance-none block w-full px-4 text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mb-5 w-36 h-8 text-base text-xs font-medium" >
                                                    <option value="complete" defaultValue>Completed</option>
                                                    <option value="hold">On Hold</option>
                                                    <option value="ongoing">Ongoing</option>
                                                </select>
                                                <span className="absolute right-4 top-2 mt-1 z-0"><svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 7L5.82818e-07 0.333333L10 0.333334L5 7Z" fill="#656A6F"></path></svg></span>
                                            </div>

                                            <button disabled={isLoading} onClick={() => { updateProjectStatus() }} className="font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-5 rounded-md ml-2 font-medium">Update
                                                {isLoading ? <Loader /> : null}
                                            </button>
                                        </div>


                                        <div className="text-tiny font-semibold text-black pb-1 ">Client</div>
                                        <div className="mb-5 text-tiny text-secondary-grey-200 text-tiny">{DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.client && DetailedProjectData.project.client.name}</div>

                                        <div className="text-tiny font-semibold text-black pb-2">Type</div>
                                        <div className="mb-5 text-tiny text-secondary-grey-200 text-tiny">{DetailedProjectData && DetailedProjectData.project ? DetailedProjectData.project.platform === 'web' ? 'Web App' : 'Mobile App' : null}  </div>

                                        <div className="text-tiny font-semibold text-black pb-3">Technologies</div>
                                        <div className="mb-5">
                                            {servertechnologies}
                                            {clienttechnologies}
                                        </div>
                                        <button onClick={() => { handleModal(); }} className="font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-5 rounded-md font-medium">
                                            Edit Project Basic Info
                                        </button>
                                    </div>

                                </div>

                            </div>
                            <div className="block md:hidden">
                                <div className='flex justify-center mb-3'>
                                    {
                                        !mobStatusView && !['files', 'requirement', 'technologies', 'member'].includes(status) &&
                                        <p onClick={() => setMobStatusView(true)} className='bg-primary text-xs text-white h-8 flex justify-center items-center rounded-md w-28'>
                                            More Details
                                        </p>
                                    }
                                </div>

                                {
                                    mobStatusView &&
                                    <div className="pl-7 pt-2 right-0 top-[75px] w-full h-full fixed z-50 bg-secondary-grey-100">
                                        <div className="flex justify-between pr-3 text-tiny font-semibold text-black pb-2">
                                            <p>Status</p>
                                            <svg onClick={() => setMobStatusView(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>

                                        </div>
                                        <div className="flex">
                                            <div className="relative">
                                                <select
                                                    onChange={(evt) => { setProjectStatus(evt.target.value) }}
                                                    value={projectStatus}
                                                    className="form-select form-select-lg appearance-none block w-full px-4 text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none mb-5 w-36 h-8 text-base text-xs font-medium" >
                                                    <option value="complete" defaultValue>Completed</option>
                                                    <option value="hold">On Hold</option>
                                                    <option value="ongoing">Ongoing</option>
                                                </select>
                                                <span className="absolute right-4 top-2 mt-1 z-0"><svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 7L5.82818e-07 0.333333L10 0.333334L5 7Z" fill="#656A6F"></path></svg></span>
                                            </div>

                                            <button disabled={isLoading} onClick={() => { updateProjectStatus() }} className="font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-5 rounded-md ml-2 font-medium">Update
                                                {isLoading ? <Loader /> : null}
                                            </button>
                                        </div>


                                        <div className="text-tiny font-semibold text-black pb-1 ">Client</div>
                                        <div className="mb-5 text-tiny text-secondary-grey-200 text-tiny">{DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.client && DetailedProjectData.project.client.name}</div>

                                        <div className="text-tiny font-semibold text-black pb-2">Type</div>
                                        <div className="mb-5 text-tiny text-secondary-grey-200 text-tiny">{DetailedProjectData && DetailedProjectData.project ? DetailedProjectData.project.platform === 'web' ? 'Web App' : 'Mobile App' : null}  </div>

                                        <div className="text-tiny font-semibold text-black pb-3">Technologies</div>

                                        <div className="mb-5">
                                            {servertechnologies}
                                            {clienttechnologies}
                                        </div>

                                        <button onClick={() => { handleModal(); }} className="font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-5 rounded-md font-medium">
                                            Edit Project Basic Info
                                        </button>
                                    </div>
                                }

                            </div>

                        </>
                        : null}

                </div>
            </div>

            <ModalLayout closeModal={handleModal} open={open}>
                <ProjectCreate closeModal={handleModal} projectInfoEdit={DetailedProjectData && DetailedProjectData.project} />
            </ModalLayout>
        </>
    );
}


export default ProjectDetails
