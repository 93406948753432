import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import Loader from '../Utilities/Loader';
import Notes from "./Notes"

function Requirement({ DetailedProjectData, projectRequirementCreation, projectRequirementUpdation, projectRequirementDelete, projectRequirementNoteCreation, projectRequirementNoteUpdation, projectRequirementNoteDelete }) {
    const [isRequirementLoading, setRequirementLoading] = useState(false);
    const [isNoteAdding, setNotAdding] = useState(false)
    const [isNoteUpdating, setNoteUpdating] = useState(false)
    const [isEditLoading, seteditLoading] = useState(false);
    const [isTitleEditing, setTitleEditing] = useState(false);
    const [editingItem, setEditingItem] = useState({ _id: "", title: "", description: "", createdAt: "", status: "", updatedAt: "", notes: [] })
    const requirementData = DetailedProjectData && DetailedProjectData.project && DetailedProjectData.project.requirement;
    const [updatingValue, setUpdatingValue] = useState({ project_id: "", requirement_id: "", title: "", description: "" })
    const titleRef = useRef(null)
    const timeoutRef = useRef(null)
    const AddRequirement = async () => {
        try {
            setRequirementLoading(true);
            const responseData = await projectRequirementCreation({
                project_id: DetailedProjectData.project._id,
                title: `Requirement`,
                description: "",
            });
            setRequirementLoading(false);
        } catch (err) {
            console.log(err);
            setRequirementLoading(false);
        }
    }
    const LoadRequirementDetails = requirementID => {
        //console.log(requirementID)
        setEditingItem({ _id: "", title: "", description: "", createdAt: "", status: "", updatedAt: "", notes: [] })
        const selectedRequirement = requirementData.filter(item => item._id === requirementID);
        const data = {
            ...updatingValue,
            requirement_id: selectedRequirement[0]._id,
            title: selectedRequirement[0].title,
            description: selectedRequirement[0].description
        }
        setUpdatingValue(data)
        setTimeout(() => {
            setEditingItem(selectedRequirement[0])
        }, 50)

    }
    const setValue = (field, value) => {
        const data = { ...updatingValue }
        data[field] = value
        setUpdatingValue(data)
    }
    const ModifyRequirementTitle = (e) => {
        try {
            setTitleEditing(true);
            setValue('title', e.target.value)
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(async () => {
                const responseData = await projectRequirementUpdation({ ...updatingValue, title: e.target.value });
                if (responseData && responseData.success) {
                    setTitleEditing(false)
                }
            }, 2000)

        } catch (error) {
            throw error
        }
    }
    const setFocus = () => {
        setTimeout(() => {
            titleRef.current.focus()
        }, 100)
    }
    const UpdateRequirement = async () => {
        try {
            seteditLoading(true)
            const responseData = await projectRequirementUpdation(updatingValue);
            if (responseData && responseData.success) {
                const selectedRequirement = responseData.project.requirement.filter(item => item._id === updatingValue.requirement_id);
                // setEditingItem(selectedRequirement[0])
                // setUpdatingValue({ project_id: DetailedProjectData.project._id, requirement_id: selectedRequirement[0]._id, title: selectedRequirement[0].title, description: selectedRequirement[0].description })
                toast.success("Requirement updated successfully");
                seteditLoading(false)
            } else {
                seteditLoading(false)
                toast.error(responseData.message);
            }
        } catch (error) {
            throw error
        }
    }
    const RemoveRequirement = async (requirementID) => {
        try {
            if (window.confirm("Are your sure you want to remove this requirement?")) {
                const responseData = await projectRequirementDelete({ project_id: DetailedProjectData.project._id, requirement_id: requirementID });
                if (responseData && responseData.success) {
                    if (responseData.project.requirement.length) {
                        const selectedRequirement = responseData.project.requirement[0];
                        setEditingItem(selectedRequirement)
                        setUpdatingValue({ project_id: DetailedProjectData.project._id, requirement_id: selectedRequirement._id, title: selectedRequirement.title, description: selectedRequirement.description })
                    } else {
                        setEditingItem({ _id: "", title: "", description: "", createdAt: "", status: "", updatedAt: "", notes: [] })
                        setUpdatingValue({ project_id: "", requirement_id: "", title: "", description: "" })
                    }
                    toast.success("Requirement removed successfully");
                } else {
                    toast.error(responseData.message);
                }
            }
        } catch (error) {
            throw error
        }
    }
    const setInitailDataDisplay = () => {
        console.log(editingItem._id)
        if (requirementData.length) {
            const selectedRequirement = editingItem._id && requirementData.filter(item => item._id === editingItem._id);
            if (selectedRequirement.length) {
                setEditingItem(selectedRequirement[0])
                setUpdatingValue({ project_id: DetailedProjectData.project._id, requirement_id: selectedRequirement[0]._id, title: selectedRequirement[0].title, description: selectedRequirement[0].description })
            } else {
                setEditingItem(requirementData[0])
                setUpdatingValue({ project_id: DetailedProjectData.project._id, requirement_id: requirementData[0]._id, title: requirementData[0].title, description: requirementData[0].description })
            }

        } else {
            setEditingItem({ _id: "", title: "", description: "", createdAt: "", status: "", updatedAt: "", notes: [] })
            setUpdatingValue({ project_id: "", requirement_id: "", title: "", description: "" })
        }
    }
    const AddNote = async (requirementID) => {
        try {
            setNotAdding(true)

            const responseData = await projectRequirementNoteCreation({
                project_id: DetailedProjectData.project._id,
                requirement_id: requirementID,
                noteTitle: `Note`,
                noteDescription: "",
            });
            if (responseData && responseData.success) {
                setNotAdding(false)
                toast.success("Note created successfully");
            } else {
                setNotAdding(false)
                toast.error(responseData.message);
            }
        } catch (err) {
            console.log(err)
        }
    }
    const EditNote = async (updatingValues) => {
        try {
            setNoteUpdating(true)
            const responseData = await projectRequirementNoteUpdation(updatingValues)
            if (responseData && responseData.success) {
                setNoteUpdating(false)
                toast.success("Note updated successfully");
            } else {
                setNoteUpdating(false)
                toast.error(responseData.message);
            }
        } catch (error) {
            throw error
        }
    }
    const RemoveNote = async (removingNoteData) => {
        const responseData = await projectRequirementNoteDelete({ ...removingNoteData });
        if (responseData && responseData.success) {
            toast.success("Note removed successfully");
        } else {
            toast.error(responseData.message);
        }
    }
    useEffect(() => {
        setInitailDataDisplay()
    }, [DetailedProjectData])

    return (
        <>
            <div className="flex flex-col lg:flex-row  ">
                <div className="md:basis-1/5 lg:border-r md:border-r">
                    <ul className="pl-0">
                        <li key="requirement-add-button" className="text-sm p-5 border-b border-x-white border-gray-200 last-of-type:border-0 bg-white">

                            <button onClick={AddRequirement} type="button" className=" w-full font-title flex items-center justify-center bg-primary-navyBlue text-white text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 px-5 rounded-md h-[40px]">
                                {
                                    isRequirementLoading ?
                                        <Loader />
                                        :
                                        <>
                                            <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="20" height="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11 11h-7.25c-.414 0-.75.336-.75.75s.336.75.75.75h7.25v7.25c0 .414.336.75.75.75s.75-.336.75-.75v-7.25h7.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-7.25v-7.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fillRule="nonzero" fill='#fff' /></svg>
                                            Add
                                        </>
                                }
                            </button>

                        </li>
                        {
                            requirementData.length ?
                                requirementData.map(requirement => {
                                    return <li key={`requirement_${requirement._id}`} onClick={() => { LoadRequirementDetails(requirement._id) }} className={`text-sm px-5 py-4 border-b border-gray-200 last-of-type:border-0 ${editingItem._id === requirement._id ? 'text-primary-navyBlue selected-requirement-highlight' : 'bg-white'}  font-title font-semibold flex justify-start cursor-pointer`}>
                                        {requirement.title}
                                        {
                                            (moment(requirement.createdAt).diff(moment(requirement.updatedAt)) !== 0) ? "" : <span><small className='ml-2 text-green-300 text-xs italic'>new</small></span>
                                        }
                                    </li>
                                })
                                :
                                <li key="no-data-item" className="text-sm p-3 border-b border-r border-gray-200 last-of-type:border-0 bg-white hover:bg-primary-light flex items-center justify-center">No data</li>
                        }
                    </ul>
                </div>
                <div className="basis-4/5  md:pl-8 mr-5">
                    {
                        (editingItem._id)
                            ?
                            (
                                <>
                                    <div className="flex flex-col bg-secondary-grey-100 mt-3 p-5 rounded-xl">
                                        <div className="w-full flex items-center lg:flex-row justify-between pt-3 pb-4">
                                            <div className="w-3/4 flex items-center lg:flex-row justify-start">
                                                <input value={updatingValue.title}
                                                    onChange={(e) => { ModifyRequirementTitle(e) }}
                                                    onBlur={() => {
                                                        !updatingValue.title && setValue('title', editingItem.title)
                                                    }}
                                                    ref={titleRef}
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    className="mr-2 items-center focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md h-10 py-1 px-4  w-150 placeholder-secondary-grey-300 font-title font-semibold border-secondary-grey-400" />
                                                {
                                                    isTitleEditing ?
                                                        <span className="ml-1 mr-2 w-15 text-secondary-grey-300 font-medium text-xs italic font-base ">saving ...</span>
                                                        :
                                                        ""

                                                }
                                                <span className="hidden md:block ml-4 w-20  text-[#000] font-normal text-xs font-base ">
                                                    Updated on
                                                </span>
                                                <span className="hidden md:block w-40 text-[#000] font-normal text-xs  font-base ">
                                                    {moment().format('MMMM DD, YYYY')}
                                                </span>

                                                <div className='block sm:flex md:hidden'>
                                                    <p className="w-20  text-[#000] font-normal text-xs font-base ">
                                                        Updated on
                                                    </p>
                                                    <p className="w-40 text-[#000] font-normal text-xs  font-base ">
                                                        {moment().format('MMMM DD, YYYY')}
                                                    </p>
                                                </div>
                                                
                                            </div>

                                        </div>

                                        <div className="mb-5 h-60 common-editor   rounded-xl relative ">
                                            <ReactQuill className="font-xl h-full bg-white rounded-xl border border-secondary-grey-400"
                                                theme="snow"
                                                too
                                                value={updatingValue.description} onChange={(val) => { setValue('description', val) }} />
                                        </div>

                                        <div className="flex items-center lg:flex-row justify-start">
                                            <button disabled={false} onClick={() => { UpdateRequirement() }}
                                                className=" mr-2 font-title flex items-center justify-between border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80  pr-3 pl-3 py-2  rounded-md font-medium">
                                                {isEditLoading ? <Loader /> : <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" className='mr-2'><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" fill="#fff" /></svg>}
                                                Update
                                            </button>
                                            <button className="group  pr-3 pl-3 py-2 font-title text-xs flex items-center justify-between hover:bg-primary-navyBlue text-primary-navyBlue  hover:text-white border border-primary-navyBlue hover:border-transparent rounded transition duration-300 ease-out rounded-md " title='group remove requirement' onClick={() => RemoveRequirement(editingItem._id)}>
                                                <svg className="fill-primary-navyBlue group-hover:fill-white mr-2" width="17" height="17" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z" /></svg>
                                                Delete
                                            </button>
                                        </div>
                                    </div>


                                    <div className="w-full flex flex-col lg:flex-col md:flex-col pt-6 pb-4">
                                        <Notes ProjectData={DetailedProjectData} RequirementData={requirementData} editingItem={editingItem} notes={editingItem.notes.reverse()} setEditingItem={setEditingItem} AddNote={AddNote} EditNote={EditNote} RemoveNote={RemoveNote} isNoteAdding={isNoteAdding} isNoteUpdating={isNoteUpdating} />
                                    </div>
                                </>
                            )
                            :
                            (
                                <div className="bg-secondary-grey-100 text-sm font-medium text-center p-8 mt-10 text-gray-500 text-center">

                                    {requirementData.length ?
                                        <Loader />
                                        :
                                        <span className="block mb-1">No data</span>

                                    }
                                </div>
                            )
                    }
                </div>
            </div>
        </>
    )
}

const mapState = (state) => ({

});
const mapDispatch = (dispatch) => ({
    projectRequirementCreation: dispatch.projectDetails.projectRequirementCreation,
    projectRequirementUpdation: dispatch.projectDetails.projectRequirementUpdation,
    projectRequirementDelete: dispatch.projectDetails.projectRequirementDelete,
    projectRequirementNoteCreation: dispatch.projectDetails.projectRequirementNoteCreation,
    projectRequirementNoteUpdation: dispatch.projectDetails.projectRequirementNoteUpdation,
    projectRequirementNoteDelete: dispatch.projectDetails.projectRequirementNoteDelete
});


export default connect(mapState, mapDispatch)(Requirement)