
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from '../../components/shared/Header';
import Project from '../../containers/pages/projects/Project';
import ProjectCreate from '../../containers/pages/projects/ProjectCreate';
import ProjectDetails from '../../containers/pages/projects/ProjectDetails';
import People from '../../containers/pages/people/People';
import Issues from '../../containers/pages/issues/Issues';
import Member from '../../containers/pages/members/Member';
import Profile from '../../containers/pages/profile/Profile';
import PasswordReset from '../../containers/pages/profile/Passwordreset';
import BookedTime from '../../containers/pages/time/BookedTime';
import TasksAssigned from '../../containers/pages/tasks/TaskAssigned';


export function Admin() {

    // console.log('loged as admin');

    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/projects/create" element={<ProjectCreate />} />
                <Route path="/projects/:name" element={<ProjectDetails />} />
                <Route path="/projects" element={<Project />} />
                <Route path="/availability" element={<People />} />
                <Route path="/people" element={<Member />} />
                <Route path="/issues" element={<Issues />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<PasswordReset />} />
                <Route path="/teamwork-time" element={<BookedTime />} />
                <Route path="/tasks-assigned" element={<TasksAssigned />} />
                <Route path="/" element={<Navigate to="/projects" />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {/* <Footer /> */}
        </div>
    );
}