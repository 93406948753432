import { auth } from "./auth"
import { counter } from "./counter"
import { projects } from "./projects"
import { people } from "./people"
import { projectDetails } from "./projectDetails"
import { issues } from "./issues"
import { time } from "./time"

export default {
    counter,
    projects,
    auth,
    people,
    projectDetails,
    issues,
    time
}