import React, { memo, useState } from 'react';
import moment from 'moment';
import ModalLayout from './ModalLayout';
import ProjectInfo from '../../containers/pages/projects/ProjectCreate';
import AddResources from '../../containers/pages/projects/AddResources';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HeaderStrip = memo(({ page, getStatus, handleChange, dataStatus, filterDate, projectListData, searchSelect, projectsData, startDate, endDate, isWeekday, onChange, projectSearch }) => {

  const [headerTitle, setHeaderTitle] = useState("Ongoing")
  const [open, SetOpenModal] = useState(false);
  const [stepOne, setStepOne] = useState(true);


  const nextStep = (stepComplete) => {
    if (!stepComplete) {
      setStepOne(false);
    } else {
      setStepOne(true);
    }
  }

  const headerData = [
    {
      id: 1,
      label: "Ongoing",
      status: "ongoing"
    },
    {
      id: 2,
      label: "Completed",
      status: "complete"
    },
    {
      id: 3,
      label: "On Hold",
      status: "hold"
    },
    {
      id: 4,
      label: "All",
      status: "all"
    }
  ]

  const handleModal = () => {
    SetOpenModal(!open);
  }


  return (
    <>
      <div className="w-full">
        {
          page === "projectPage" ?

            <div className="border-b pt-3 px-6">
              <div className="flex items-center last:pb-0">
                {headerData && headerData.map((item) =>
                  <button key={item.id} onClick={() => { getStatus(item.status); setHeaderTitle(item.label) }} className={`pb-3 ${dataStatus === item.status ? "border-primary-navyBlue " : "border-transparent"}   text-sm text-secondary border-b-2 transition duration-300 ease-out hover:border-primary-navyBlue mr-5   ${item.label === "All" ? "ml-3 relative all-button" : ""}`}>{item.label}</button>
                )}

              </div>
            </div>
            :
            null
        }

        <div className="px-6 pt-6">
          <div className="border-b flex flex-wrap items-center w-full justify-between pb-6">

            <div className="mb-2 sm:mb-0">
              {page === "projectPage" ?
                <h3 className="font-title text-xl text-title font-medium">{headerTitle}&nbsp;Projects&nbsp;({projectsData && projectsData.totalcount})</h3>
                :
                <h3 className="font-title text-xl text-title font-medium">People</h3>
              }
            </div>

            <div className="flex flex-wrap items-center w-full sm:w-auto">
              {
                page === "projectPage" ?

                  <div className="mr-2 custom-select-wrapper relative w-[60%] sm:w-auto">
                    <div className="absolute top-2/4 -translate-y-2/4 left-2 z-10">
                      <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z" fill="#54585D" />
                      </svg>
                    </div>
                    <input className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear  border rounded-md text-xs px-6 w-full placeholder-secondary-grey-300 text-secondary-grey-300 border-secondary-grey-400 h-8" type="text"
                      onChange={(evt) => { projectSearch(evt.target.value) }} />
                  </div>
                  :
                  <div className="block sm:flex items-center">
                    <p className="text-md text-black font-medium inline-block mr-5 mt-2 sm:mt-0">Selected date:  <span></span>{startDate && moment(startDate).format('MMMM')} {startDate && moment(startDate).format('DD')} - {endDate && moment(endDate).format('DD')}</p>

                    <div className="relative mb-4 sm:mb-0 mr-2 mt-4 sm:mt-0">
                      <div>
                        <DatePicker className="custom-datepicker-wrapper relative appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-xs border rounded-md px-3 pr-8 w-full sm:w-56 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 ring-transparent text-secondary-grey-200 ring-0 h-8"
                          onChange={onChange}
                          startDate={startDate}
                          dateFormat="dd-MM-yyyy"
                          endDate={endDate}
                          selectsRange
                          filterDate={isWeekday}
                          shouldCloseOnSelect={true}
                        />
                        <span className="cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.04708 0.0437498C2.90958 0.106249 2.78771 0.231247 2.71897 0.381245C2.66272 0.503119 2.65647 0.590617 2.65647 1.25623V1.99997H2.16899C1.59714 1.99997 1.41902 2.03747 1.04716 2.2281C0.709672 2.40309 0.403435 2.70934 0.228442 3.04684C-0.0184232 3.52495 0.000326034 3.05309 0.000326034 8.99988C0.000326034 14.9467 -0.0184232 14.4748 0.228442 14.9529C0.403435 15.2904 0.709672 15.5967 1.05028 15.7748C1.51901 16.0154 0.972161 15.9998 8 15.9998C15.0278 15.9998 14.481 16.0154 14.9497 15.7748C15.2903 15.5967 15.5966 15.2904 15.7716 14.9529C16.0184 14.4748 15.9997 14.9467 15.9997 8.99988C15.9997 3.05309 16.0184 3.52495 15.7716 3.04684C15.5966 2.70934 15.2903 2.40309 14.9528 2.2281C14.581 2.03747 14.4029 1.99997 13.831 1.99997H13.3435V1.25623C13.3435 0.521869 13.3435 0.509369 13.2654 0.353121C13.2154 0.253121 13.1373 0.159373 13.056 0.106249C12.9279 0.015625 12.9248 0.015625 12.3717 0.00625038C11.7592 -0.00625038 11.678 0.0124998 11.4936 0.209373C11.3342 0.37812 11.3124 0.503119 11.3124 1.28436V1.99997H8H4.68763V1.29373C4.68763 0.521869 4.67201 0.412495 4.52514 0.234372C4.35015 0.0249996 4.25953 0 3.6658 0C3.30019 0.00312519 3.1127 0.015625 3.04708 0.0437498ZM14.6497 10.4436L14.6404 14.1717L14.5529 14.3186C14.4966 14.4154 14.4154 14.4967 14.3185 14.5529L14.1716 14.6404H8H1.82838L1.68151 14.5529C1.58464 14.4967 1.50339 14.4154 1.44714 14.3186L1.35965 14.1717L1.35027 10.4436L1.34402 6.71866H8H14.656L14.6497 10.4436Z" fill="#54585D"></path></svg></span>
                      </div>
                    </div>

                  </div>
              }


              <div className="flex items-center">
                {
                  page === "projectPage" ?
                    <button onClick={() => handleModal()} className="font-title flex items-center bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-4 rounded-md">
                      <span className="text-xl text-white -mt-[3px] flex mr-1">+</span>Add Project
                    </button>
                    :
                    null
                }
              </div>

            </div>

          </div>
        </div>
      </div>

      <ModalLayout closeModal={handleModal} open={open}>
        {
          page === "projectPage" ?
            <>
              {stepOne ?
                <ProjectInfo nextStep={nextStep} />
                :
                <AddResources closeModal={handleModal} />}
            </> : null
        }
      </ModalLayout>

    </>
  )
});

export default HeaderStrip