//export const API_BASE_PATH = "http://localhost:8000/api";
///export const API_BASE_PATH = "https://toobler-document-api.herokuapp.com/api"; 

//main branch = staging 
export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH
console.log(API_BASE_PATH)
//production branch = production 
//export const API_BASE_PATH = "https://availability-api.toobler.com/api";





export const API_ROUTES = {
    "projects": "/project/allprojects",
    "login": "/login",
    "token": "/token",
    "project-create": "/project/create",
    "project-client": "/project/allclients",
    "project-name-search": "/project/search/titlesearch/",
    "project-technology": "/project/alltechnologies",
    "forgot-password": "/forgot-password",
    "reset-password": "/reset-password",
    "search-user": "/search-user",
    "invite-user": "/invite-user",
    "designation": "/designations",
    "roles": "/roles",
    "skills": "/skills",
    "register": "/register",
    "members": "/members",
    "all-members": "/all-members",
    "project-detail": "project/",
    "all-issues": "/project/issues/all-issues/",
    'all-invite-user': "/all-invite-user",
    'user-availability-calender': "/user-availability-calender",
    'project-status-change': "/project/update/status/",
    'project-description-change': "/project/update/",
    'session': "/session/",
    'project-requirement': "/project/requirement/",
    'project-technology-update': "/project/technology/",
    'project-roadmap': "/project/roadmap/",
    'member-availability-calender': '/member-availability-calender',
    'member-availability-statistics': '/member-availability-statistics',
    'member-availability-statistics-user_id': '/user-availability-calender',
    'profile-update': '/profile-update',
    'change_password': '/change_password',
    'time_entries':'/tw/time/tw-get-time-entries',
    'booked_time':'/tw/time/member-booked-hours',
    'project-files-upload':'/project/files/upload',
    'project-files-get':'/project/files/get',
    'project-files-download':'/project/files/download',
    'project-files-delete':'/project/files/delete',
    "list-tasks":"project/task/list-tasks",
    "list-task-history":"project/task/list-task-update-history",

}
