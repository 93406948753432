import React, { useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { toast } from 'react-toastify';

const AddMember = ({
  designationResponseData,
  designationSearch,
  rolesResponseData,
  rolesSearch,
  skillsResponseData,
  skillsSearch,
  register,
  closeModal,
  memberUpdate,
  memberEditData,
  onSuccess
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      designationSearch();
      rolesSearch();
      skillsSearch();
    }
    fetchData();
  }, [designationSearch, rolesSearch, skillsSearch]);


  return (
    <div className="w-full">
      <div className="mb-12">
        <h3 className="font-title text-xl font-medium text-black mb-2">
          {memberEditData && memberEditData._id ? 'Edit Member' : 'Add a New Member'}
        </h3>
        <span className="block text-sm text-secondary-grey-200">
          Enter basic information about the new member.
        </span>
      </div>

      <Formik
        initialValues={{
          first_name: (memberEditData && memberEditData.first_name) || "",
          last_name: (memberEditData && memberEditData.last_name) || "",
          designation: (memberEditData && memberEditData.designation && memberEditData.designation._id) ? {value:memberEditData.designation._id,label:memberEditData.designation.name} : "",
          roles: (memberEditData && memberEditData.roles && memberEditData.roles.length > 0 && memberEditData.roles.map(roleitem => {
            return roleitem._id
          })) || [],
          email: (memberEditData && memberEditData.email) || "",
          password: "",
          skills: (memberEditData && memberEditData.skills && memberEditData.skills.length > 0 && memberEditData.skills.map(roleitem => {
            return roleitem._id
          })) || [],
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required("Enter First Name"),
          last_name: Yup.string().required("Enter Last Name"),
          designation: Yup.object().required("Choose Designation"),
          roles: Yup.array().min(1, "Choose Roles"),
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: memberEditData && memberEditData._id ? null : Yup.string().required("Enter Password"),
          skills: Yup.array().min(1, "Choose Skills"),
        })}


        onSubmit={async (values, { resetForm }) => {
            values.designation = values.designation.value;
          if (memberEditData && memberEditData._id) {
            await memberUpdate({ ...values, user_id: memberEditData._id })
              .then((response) => {
                if (response.message && response.message.length > 0) {
                  toast.success(response.message);
                }
                if (response.success) {
                  onSuccess();
                  closeModal(true);
                }
              }).catch((response) => {
                if (response.error && response.error.length > 0) {
                  toast.error(response.error);
                }
              });

          } else {
            await register(values)
              .then((response) => {
                if (response.message && response.message.length > 0) {
                  toast.success(response.message);
                }
                if (response.status === 200) {
                  onSuccess();
                  closeModal(true);
                }
              }).catch((response) => {
                if (response.error && response.error.length > 0) {
                  toast.error(response.error);
                }
              });
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className="w-full">
            <div className="mb-8 relative grid grid-cols-2 gap-4">
              <div>
                <Field
                  className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                  placeholder="First Name"
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  value={values.first_name}
                />
                {errors.first_name && touched.first_name && (
                  <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                    {errors.first_name}
                  </span>
                )}
              </div>
              <div className="relative">
                <Field
                  className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                  placeholder="Last Name"
                  type="text"
                  name="last_name"
                />
                {errors.last_name && touched.last_name && (
                  <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                    {errors.last_name}
                  </span>
                )}
              </div>
            </div>

            <div className="mb-8 relative">
              <Select
                className="custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 css-b62m3t-container"
                name="designation"
                defaultValue={memberEditData && memberEditData.designation ? { value: memberEditData.designation._id, label: memberEditData.designation.name } : ""}
                //isMulti
                isSearchable={true}
                classNamePrefix="dropdownSelect"
                isClearable={true}
                options={designationResponseData.map((project) => ({
                  label: project.name,
                  value: project._id,
                }))}
                placeholder="Designation"
                onChange={(e) =>{
                  if(e){
                    setFieldValue("designation",e)
                  }else{
                    setFieldValue("designation","")
                  }
                }}
              />
              {errors.designation && touched.designation && (
                <span className="text-red-500 text-xs absolute -bottom-5">
                  {errors.designation}
                </span>
              )}
              {/* <span className="absolute right-4 top-5 mt-1 z-0">
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7L5.82818e-07 0.333333L10 0.333334L5 7Z" fill="#656A6F" />
                </svg>
              </span> */}


            </div>

            <div className="mb-8 relative">
              <Select
                className="custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                placeholder="Application Role"
                type="text"
                isMulti
                name="roles"
                classNamePrefix="dropdownSelect"
                defaultValue={(memberEditData && memberEditData.roles && memberEditData.roles.length > 0 && memberEditData.roles.map(roleitem => {
                  return { value: roleitem._id, label: roleitem.name }
                })) || []}
                options={rolesResponseData.map((project) => ({
                  label: project.name,
                  value: project._id,
                }))}
                onChange={(e) => {
                  setFieldValue(
                    "roles",
                    e.map((rol) => rol.value)
                  );
                }}
              />

              {errors.roles && touched.roles && (
                <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                  {errors.roles}
                </span>
              )}
            </div>

            <div className="mb-8 relative dddd">
              <Field
                className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                placeholder="Login Email"
                type="text"
                name="email"
                autoComplete="off"
              />
              {errors.email && touched.email && (
                <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                  {errors.email}
                </span>
              )}
            </div>


            {
              !(memberEditData && memberEditData._id) ?
                <div className="mb-8 relative">
                  <Field
                    className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                    placeholder="Password"
                    type="password"
                    name="password"
                  />
                  {errors.password && touched.password && (
                    <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                      {errors.password}
                    </span>
                  )}
                </div> : null
            }



            <div className="mb-8 relative">
              <Select
                className="custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                placeholder="Skills"
                type="text"
                name="skills"
                classNamePrefix="dropdownSelect"
                defaultValue={(memberEditData && memberEditData.skills && memberEditData.skills.length > 0 && memberEditData.skills.map(skillitem => {
                  return { value: skillitem._id, label: skillitem.name }
                })) || []}
                isMulti
                options={skillsResponseData.map((project) => ({
                  label: project.name,
                  value: project._id,
                }))}
                onChange={(e) => {
                  setFieldValue(
                    "skills",
                    e.map((sk) => sk.value)
                  );
                }}
              />
              {errors.skills && touched.skills && (
                <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                  {errors.skills}
                </span>
              )}
            </div>

            <button
              disabled={isSubmitting}
              type="submit"
              className="w-full p-4 bg-primary-navyBlue hover:bg-opacity-75 transition delay-150 duration-150 ease-linear text-white rounded-md text-sm font-semibold font-title"
            >
              Save
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapState = (state) => ({
  designationResponseData: state.projects.designation,
  rolesResponseData: state.projects.roles,
  skillsResponseData: state.projects.skills,
});

const mapDispatch = (dispatch) => ({
  designationSearch: dispatch.projects.designationSearch,
  rolesSearch: dispatch.projects.rolesSearch,
  skillsSearch: dispatch.projects.skillsSearch,
  register: dispatch.projects.register,
  memberUpdate: dispatch.people.memberUpdate
});

export default connect(mapState, mapDispatch)(AddMember);
