import React, { useState } from 'react';
import Loader from '../Utilities/Loader';

// import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'
const Accordion = ({ title, children, index, activeIndex, setActiveIndex, data, updateSubItem, deleteSubItem, roadmapId,deletesubTaskLoader,updatesubTaskLoader }) => {

  const handleSetIndex = (index) => (activeIndex !== index) && setActiveIndex(index);

  return (
    <>
      <div className="rounded bg-white  bg-white mb-3 border p-3">
        <div onClick={() => handleSetIndex(index)} className="flex w-full justify-between items-center">
          <div className='accordion-button relative flex items-center w-full text-base text-gray-800 text-left bg-white transition focus:outline-none '>
            <div id={`issue-title-${index}`} contenteditable="true" className={`text-sm text-black font-semibold text-title capitalize break-all hover:cursor-pointer w-full p-1.5  outline-1 outline-primary-navyBlue`}>{title}</div>
          </div>
          <div className="flex items-end font-bold text-3xl cursor-pointer">
            {
              (activeIndex === index)
                ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="4" width="12" height="3" fill="black" />
                </svg>
                : <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 4.5H7.5V0H4.5V4.5H0V7.5H4.5V12H7.5V7.5H12V4.5Z" fill="#2E2E2E" />
                </svg>
            }
          </div>
        </div>

        {(activeIndex === index) && (
          <>
            <div className="shadow-3xl rounded-none border-borderColor-100 pb-3 border-b  pt-1">
              <p id={`issue-description-${index}`} contenteditable="true" className="font-base text-sm w-full p-1.5 outline-1 outline-primary-navyBlue">{children}</p>
            </div>
            <div className="flex pt-3 mb-3">
              <button disabled={data._id === updatesubTaskLoader} onClick={ () => {
                let issuetitle = document.getElementById(`issue-title-${index}`).innerText;
                let issuedescription = document.getElementById(`issue-description-${index}`).innerText;
                let body = {
                  title: issuetitle,
                  description: issuedescription

                }
               updateSubItem(data, roadmapId, body);
              }}
                className="font-title flex items-center border border-primary-navyBlue bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 p-4 rounded-md font-semibold">Submit
                {updatesubTaskLoader === data._id ? <Loader /> : null}

              </button>

              <button disabled={data._id === deletesubTaskLoader} onClick={() => { deleteSubItem(data, roadmapId) }} className="font-title flex items-center border border-red-600  ml-4 text-red-600 text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 p-4 rounded-md font-semibold">Delete
              {deletesubTaskLoader === data._id ? <Loader /> : null}
              </button>
              
            </div>
          </>)}

      </div>
    </>
  );
};

export default Accordion;