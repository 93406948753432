import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import Loader from '../Utilities/Loader';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download'
function ProjectDocuments({ DetailedProjectData, projectDocumentsData, doFileUpload, getProjectDocuments, downloadFile, deleteFile }) {
    const [loading, setLoadig] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [data, setData] = useState({ document: "", description: "", documentName: "" });
    const fileRef = useRef();

    const headerTitle = [
        {
            id: '1',
            label: 'Files',
            classwidth: '2/3',
        },
        {
            id: '2',
            label: 'Description',
            classwidth: '3/4',
        },
        {
            id: '3',
            label: 'Actions',
            classwidth: '1/6',
        }
    ]

    const setValue = (field, value) => {
        const changedData = { ...data }
        changedData[field] = value
        setData(changedData)
    }
    const resetField = () => {
        setData({ document: "", description: "", documentName: "" })
        fileRef.current.value = null;
    }
    const uploadFile = async () => {
        if (!data.document) {
            toast.error("no file choosen");
        } else {
            setButtonLoading(true)
            const formData = new FormData()
            formData.append('project_id', DetailedProjectData.project._id)
            formData.append('document', data.document, data.document.name)
            formData.append('description', data.description)
            formData.append('documentName', data.documentName)

            const responseData = await doFileUpload(formData);
            setButtonLoading(false)
            if (responseData.success) {
                toast.success(responseData.message);
                resetField()
                getDocuments()
            } else {
                toast.error(responseData.message);
            }
        }
    }
    const getDocuments = async () => {
        setLoadig(true)
        await getProjectDocuments({ projectId: DetailedProjectData.project._id });
        setLoadig(false)
    }
    const DownloaDocument = async (docId, filename) => {
        const response = await downloadFile({ docId });
        FileDownload(response, filename);
    }
    const ViewDocument = async (docId, filename) => {
        const response = await downloadFile({ docId });
        const fileURL = URL.createObjectURL(response);
        window.open(fileURL);
    }
    const DeleteDcoument = async (docId) => {
        if (window.confirm("Are you sure to delete this file?")) {
            const response = await deleteFile({ docId });
            if (response.success) {
                toast.success(response.message);
                getDocuments()
            } else {
                toast.error(response.message);
            }
        }
    }
    useEffect(() => {
        getDocuments()
    }, [])
    return (
        <>
            <div className="max-w-full overflow-x-auto">
                <h2 className="text-base text-black font-semibold mb-3">Choose File</h2>
                <div className="bg-secondary-grey-100 p-3 rounded block md:flex md:justify-between md:items-center mb-5 md:min-w-[768px]">
                    <input filename={data.document ? data.document.name : null}
                        ref={fileRef}
                        onChange={(e) => {
                            setValue('document', e.target.files[0]);
                        }} type="file"
                        name="document" placeholder="File" className="mr-2 items-center mt-2 md:mt-0 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md h-10 py-1 px-4 w-full md:w-1/4 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400" />

                    <input value={data.documentName} onChange={(e) => {
                        setValue('documentName', e.target.value);
                    }} type="text"
                        name="documentName" placeholder="Document Name" className="mr-2 items-center mt-2 md:mt-0  focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md h-10 py-1 px-4 w-full md:w-1/4 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400" />

                    <textarea value={data.description} placeholder="Description" name="description" onChange={(e) => setValue('description', e.target.value)} className="mr-2 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-2 px-4 w-full md:w-1/2 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 mt-2 md:mt-0 h-full md:h-10 resize-none"></textarea>

                    <button type="button"
                        className="mt-2 md:mt-0 w-24 md:w-1/12 font-title flex items-center bg-primary-navyBlue text-white text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 px-4 rounded-md h-[28px]"
                        onClick={() => uploadFile()}
                    >{!buttonLoading
                        ?
                        (<>
                            <svg height="20" width="20" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><title /><g><path d="M90,54a5.9966,5.9966,0,0,0-6,6V78H12V60A6,6,0,0,0,0,60V84a5.9966,5.9966,0,0,0,6,6H90a5.9966,5.9966,0,0,0,6-6V60A5.9966,5.9966,0,0,0,90,54Z" fill='#fff' /><path d="M34.2422,34.2422,42,26.4844V60a6,6,0,0,0,12,0V26.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844l-18-18a5.9979,5.9979,0,0,0-8.4844,0l-18,18a5.9994,5.9994,0,0,0,8.4844,8.4844Z" fill='#fff' /></g></svg>
                            Upload
                        </>
                        )
                        :
                        <Loader />
                        }</button>
                </div>
            </div>
            <div className="flex items-center lg:flex-row justify-start">
                <h2 className="text-base text-black font-semibold mb-5">Files</h2>
            </div>
            <div className="max-w-full overflow-x-auto">
                <div className="bg-white rounded flex flex-col min-w-[768px]">
                    <header className="border-b">
                        <div className="flex items-center justify-between py-3 px-3">
                            {headerTitle && headerTitle.map((item) =>
                                <div key={`header_${item.id}`} className={`text-sm text-primary-400 basis-${item.classwidth} ${item.center} pl-1 first:px-0 last:pl-3`}>{item.label}</div>
                            )}
                        </div>
                    </header>
                    <>
                        {
                            !loading && projectDocumentsData.length ?
                                projectDocumentsData.map(file => {
                                    return (<div key={`docid_${file._id}`} className="flex items-center justify-between py-3 hover:bg-primary-light transition duration-150 ease-out px-3 border-b">
                                        <div className="flex justify-start text-sm text-title basis-2/3 break-all ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M22 13v-13h-20v24h8.409c4.857 0 3.335-8 3.335-8 3.009.745 8.256.419 8.256-3zm-4-7h-12v-1h12v1zm0 3h-12v-1h12v1zm0 3h-12v-1h12v1zm-2.091 6.223c2.047.478 4.805-.279 6.091-1.179-1.494 1.998-5.23 5.708-7.432 6.881 1.156-1.168 1.563-4.234 1.341-5.702z" fill='#b5b3b3' /></svg>
                                            <h3 className='font-medium font-title font-semibold ml-3'>{file.documentName ? file.documentName : file.documentOriginalName}</h3>
                                        </div>
                                        <div className="flex justify-start text-sm text-title basis-3/4 break-all ">
                                            {file.description}
                                        </div>
                                        <div className="flex justify-start basis-1/6 break-all pr-3 sm:pr-0">
                                            <div className="mb-1 text-title text-sm font-normal flex items-center">
                                                <div className='mr-3 items-center'>
                                                    <button title="view document" onClick={() => ViewDocument(file._id, file.documentOriginalName)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z" fill='#9d9fa2' /></svg>
                                                    </button>
                                                </div>
                                                <div className='mr-3 items-center'>
                                                    <button title='download' onClick={() => DownloaDocument(file._id, file.documentOriginalName)}>
                                                        <svg height="20" width="20" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><title /><g><path d="M90,54a5.9966,5.9966,0,0,0-6,6V78H12V60A6,6,0,0,0,0,60V84a5.9966,5.9966,0,0,0,6,6H90a5.9966,5.9966,0,0,0,6-6V60A5.9966,5.9966,0,0,0,90,54Z" fill='#9d9fa2' /><path d="M43.7578,64.2422a5.9979,5.9979,0,0,0,8.4844,0l18-18a5.9994,5.9994,0,0,0-8.4844-8.4844L54,45.5156V12a6,6,0,0,0-12,0V45.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844Z" fill='#9d9fa2' /></g></svg>
                                                    </button>
                                                </div>
                                                <div className='mr-3 items-center'>
                                                    <button title='delete' onClick={() => DeleteDcoument(file._id)}>
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.0833 14H2.91666C2.27266 14 1.74999 13.4773 1.74999 12.8333V2.91667H1.16666V1.75H4.66666V0.875C4.66666 0.392583 5.05924 0 5.54166 0H8.45832C8.93957 0 9.33332 0.391417 9.33332 0.875V1.75H12.8333V2.91667H12.25V12.8333C12.25 13.4773 11.7273 14 11.0833 14ZM11.0833 2.91667H2.91666V12.5417C2.91666 12.7027 3.04732 12.8333 3.20832 12.8333H10.7917C10.9527 12.8333 11.0833 12.7027 11.0833 12.5417V2.91667ZM5.83332 5.25C5.83332 4.928 5.57199 4.66667 5.24999 4.66667C4.92799 4.66667 4.66666 4.928 4.66666 5.25V10.5C4.66666 10.822 4.92799 11.0833 5.24999 11.0833C5.57199 11.0833 5.83332 10.822 5.83332 10.5V5.25ZM9.33332 5.25C9.33332 4.928 9.07199 4.66667 8.74999 4.66667C8.42799 4.66667 8.16666 4.928 8.16666 5.25V10.5C8.16666 10.822 8.42799 11.0833 8.74999 11.0833C9.07199 11.0833 9.33332 10.822 9.33332 10.5V5.25ZM8.16666 1.16667H5.83332V1.75H8.16666V1.16667Z" fill="#F74051"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                })
                                :
                                <div className="flex justify-center items-center h-28">
                                    <p className="text-sm text-title">{`${loading ? 'Loading...' : 'No data'}`}</p>
                                </div>
                        }
                    </>
                </div>
            </div>
        </>
    )
}
const mapState = (state) => ({
    projectDocumentsData: state.projectDetails.files
});
const mapDispatch = (dispatch) => ({
    doFileUpload: dispatch.projectDetails.doFileUpload,
    getProjectDocuments: dispatch.projectDetails.getProjectDocuments,
    downloadFile: dispatch.projectDetails.downloadFile,
    deleteFile: dispatch.projectDetails.deleteFile
})

export default connect(mapState, mapDispatch)(ProjectDocuments)
