import { API_ROUTES } from "../config";
import { http } from "../http"

export const GetTimeEntries = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["time_entries"], { params: { page: data?.currentPage, limit: data?.limit, search: data?.search } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}
export const GetBookedTime = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["booked_time"], { params: { page: data?.currentPage, limit: data?.limit, search: data?.search,from:data?.from,to:data?.to } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}