import { GetTimeEntries,GetBookedTime  } from "../services/time";
export const time = {
    state: {
        timeEntries: [],
        bookedTime:[],
        bookedTimeTotalCount:""
    },
    reducers: {
        listTimeEntries: (state, data) => {
            return {
                ...state,
                timeEntries: data,
                timeEntriesTotal: data?.totalcount,
            };
        },
        listBookedTime:(state,data) =>{
            return {
                ...state,
                bookedTime:data.bookedHours,
                bookedTimeTotalCount:data?.totalcount
            }
        }
    },
    effects: (dispatch) => ({
        async getTimeEntries(data) {
            try {
                const responsedata = await GetTimeEntries(data);
                this.listTimeEntries(responsedata.data.timeEntries);
                return responsedata.data.timeEntries
            } catch (err) {
                console.log('err', err);
                throw err;
            }
        },
        async getBookedTime(data) {
            try {
                const responsedata = await GetBookedTime(data);
                this.listBookedTime(responsedata?.data?.bookedHours);
                return responsedata?.data?.bookedHours?.bookedHours
            } catch (err) {
                console.log('err', err);
                throw err;
            }
        }
    })
}