import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import './styles/styles.scss';
import Login from './components/login';
import { connect } from 'react-redux';
import RoleBase from './routers/roleBase/Rolebase';
import ForgotPassword from './components/ForgotPassword/index';
import ResetPassword from './components/ResetPassword/index';
import Toaster from './components/Common/Toaster';
import 'react-toastify/dist/ReactToastify.css';

const App = ({ userInfo, isbootstrapped, validateToken }) => {

  useEffect(() => {
    validateToken();
  }, []);

  if (isbootstrapped) {
    return (<BrowserRouter>
      <>
        {
          userInfo && userInfo.token ?
            <Routes>
              <Route path="/*" exact element={userInfo && userInfo.token ? <RoleBase usersession={userInfo} /> : <Navigate to="/login" />} />
            </Routes> :
            <Routes>
              <Route path="/" exact element={!(userInfo && userInfo.token) ? <Login /> : <Navigate to="/" />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        }
      </>
      <Toaster />
    </BrowserRouter>)
  } else {
    return (<div className="flex items-center justify-center h-screen bg-secondary-blue-300" style={{}}>
      <div className="init-loader">Loading
         <span></span>
      </div>
    </div>)
  }

}

const mapState = (state) => ({
  userInfo: state.auth.userInfo,
  isbootstrapped: state.auth.isbootstrapped
});

const mapDispatch = (dispatch) => ({
  validateToken: dispatch.auth.validateToken
});

export default connect(mapState, mapDispatch)(App)
