import moment from 'moment'
import React, { useState } from 'react'
import Loader from '../Utilities/Loader'

function Notes({ ProjectData, RequirementData, editingItem, notes, setEditingItem, AddNote, EditNote, RemoveNote, isNoteAdding, isNoteUpdating }) {
    const [editingNoteId, setEditingNoteId] = useState("")
    const [noteUpdatingValue, setNoteUpdatingValue] = useState({ project_id: ProjectData.project._id, requirement_id: editingItem._id, note_id: "", noteTitle: "", noteDescription: "" })

    const startNoteEditing = (note) => {
        const data = { ...noteUpdatingValue }
        data['note_id'] = note._id
        data['noteTitle'] = note.title
        data['noteDescription'] = note.description
        setEditingNoteId(note._id)
        setNoteUpdatingValue(data)
    }
    const CancelNoteEditing = () => {
        setEditingNoteId("")
        setNoteUpdatingValue({ project_id: ProjectData.project._id, requirement_id: editingItem._id, note_id: "", noteTitle: "", noteDescription: "" })
    }
    const setValue = (field, value) => {
        const data = { ...noteUpdatingValue }
        data[field] = value
        setNoteUpdatingValue(data)
    }
    const UpdateNote = async () => {
        try {
            await EditNote(noteUpdatingValue)
            setEditingNoteId("")
            setNoteUpdatingValue({ project_id: ProjectData.project._id, requirement_id: editingItem._id, note_id: "", noteTitle: "", noteDescription: "" })
        } catch (error) {
            throw error
        }
    }
    const DeleteNote = async (noteID) => {
        try {
            if (window.confirm("Do you want to delete this note?")) {
                await RemoveNote({ project_id: ProjectData.project._id, requirement_id: editingItem._id, note_id: noteID })
                setEditingNoteId("")
                setNoteUpdatingValue({ project_id: ProjectData.project._id, requirement_id: editingItem._id, note_id: "", noteTitle: "", noteDescription: "" })
            }
        } catch (error) {
            throw error
        }
    }
    return (
        <>

            <div className="mb-5 mt-2 p-5 w-full flex justify-between align-left items-center flex-col bg-secondary-grey-100">
                <div className='w-full mb-2 flex justify-between items-center'>
                    <h2 className="w-3/4 text-base text-black font-semibold">Notes</h2>
                    <button type="button"
                        onClick={() => { AddNote(editingItem._id) }}
                        className="group font-title text-[10px] sm:text-xs flex items-center justify-between hover:bg-primary-navyBlue text-primary-navyBlue  hover:text-white border border-primary-navyBlue hover:border-transparent rounded transition duration-300 ease-out rounded-md pr-4 pl-4 py-2">
                        <svg className="hidden sm:block fill-primary-navyBlue group-hover:fill-white mr-2" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" width="17" height="17" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m11 11h-7.25c-.414 0-.75.336-.75.75s.336.75.75.75h7.25v7.25c0 .414.336.75.75.75s.75-.336.75-.75v-7.25h7.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-7.25v-7.25c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fillRule="nonzero" /></svg>
                        Add Note
                        {
                            isNoteAdding
                                ?
                                <Loader />
                                :
                                ""
                        }
                    </button>
                </div>

                <>
                    {notes.length ?
                        notes.map(note => {
                            return (
                                (editingNoteId !== note._id)
                                    ?
                                    (<div key={`Note_${note._id}`} className="w-full flex justify-between rounded bg-white rounded-md bg-white border border-gray-200 p-3 mb-2">
                                        <div className="w-full flex flex-col lg:flex-col md:flex-col justify-between px-3">
                                            <h2 className="w-full text-base text-primary-navyBlue font-title font-semibold mb-1">
                                                {note.title}
                                                {
                                                    (moment(note.createdAt).diff(moment(note.updatedAt)) !== 0) ? "" : <span><small className='ml-2 text-green-300 text-xs italic'>new</small></span>
                                                }

                                            </h2>
                                            <p className="text-sm text-secondary-grey-300">{note.description}</p>
                                        </div>
                                        <div className="group relative flex align-center items-center flex-col">
                                            <button type="button">
                                                <svg className="fill-secondary-grey-300" width="20" height="20" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" /></svg>
                                            </button>
                                            <div className={`invisible group-hover:visible flex flex-col justify-center absolute top-6 right-0 w-28 rounded bg-white transition duration-300 ease-out drop-shadow-md z-20`}>
                                                <button
                                                    onClick={() => startNoteEditing(note)}
                                                    className="w-full p-2 flex justify-start  transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50 text-title text-semibold" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="fill-secondary-grey-300" width="30" height="17" viewBox="0 0 24 24"><path d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" /></svg>
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => DeleteNote(note._id)}
                                                    className="w-full p-2 flex justify-start  transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50" >
                                                    <svg width="30" height="17" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-9 4c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm-2-7h-4v1h4v-1z" fill='red' /></svg>
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>)
                                    :
                                    (<div key={`Note_${note._id}`} className="w-full flex justify-between flex-col lg:flex-col md:flex-col rounded bg-white rounded-md bg-white border border-gray-200 p-4 mb-2">
                                        <input value={noteUpdatingValue.noteTitle} onChange={(e) => {
                                            setValue('noteTitle', e.target.value);
                                        }} type="text"
                                            name="noteTitle" placeholder="Note Title" className="mb-2 mr-2 items-center focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base text-black font-semibold font-normal border rounded-md h-10 py-1 px-4  w-1/2 placeholder-secondary-grey-300  border-secondary-grey-400" />

                                        <input value={noteUpdatingValue.noteDescription} placeholder="Description" name="noteDescription" onChange={(e) => setValue('noteDescription', e.target.value)} className="mb-4 mr-2 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base font-normal text-black border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300  font-normal border-secondary-grey-400 h-10" />
                                        <div className="w-1/4 flex justify-start items-center flex-row lg:flex-row md:flex-row">
                                            <button type="button"
                                                onClick={() => UpdateNote()}
                                                className="w-100 font-title text-xs flex items-center justify-center hover:bg-primary-navyBlue text-primary-navyBlue  hover:text-white py-2 px-4 border border-primary-navyBlue hover:border-transparent rounded transition duration-300 ease-out rounded-md h-[28px]">
                                                Submit
                                                {
                                                    isNoteUpdating
                                                        ?
                                                        <Loader />
                                                        :
                                                        ""
                                                }
                                            </button>
                                            <button type="button"
                                                onClick={() => CancelNoteEditing()}
                                                className="w-100 font-title flex items-center justify-center bg-white text-primary-navyBlue text-tiny font-medium transition duration-300 ease-out hover:bg-opacity-80 px-4 rounded-md h-[28px]">
                                                <svg className="fill-primary-navyBlue" width="15" height="15" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" /></svg>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>)
                            )
                        })
                        :
                        (
                            <div className="bg-secondary-grey-100 text-sm font-medium text-center p-8 mt-10 text-gray-500 text-center">
                                <span className="block mb-1">No data</span>
                            </div>
                        )
                    }
                </>

            </div>

        </>
    )
}

export default Notes