import { listIssuesService } from "../services/issues";

export const issues = {
    state: {
        issues: []
    },

    reducers: {
        listIssuesData: (state, data) => {
            return {
                ...state,
                issues: data
            };
        }
    },
    effects: (dispatch) => ({
        async listIssues(data) {
            try {
                const responsedata = await listIssuesService(data);
                this.listIssuesData(responsedata.data);
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        }

    }),
}