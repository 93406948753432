
import { activeMembersListService, createuserAvailabilityListCalenderService, getSessionService, membersListService, projectDescriptionChangeService, projectDetailService, projectRequirementNoteCreationService, projectRequirementNoteDeleteService, projectRequirementNoteUpdationService, projectRequirementCreationService, projectRequirementDeleteService, projectRequirementUpdationService, projectRoadmapIssueCreationService, projectRoadmapIssueDeleteService, projectRoadmapIssueUpdateService, projectRoadmapSprintDeleteService, projectRoadmapTaskCreationService, projectStatusChangeService, projectTechnologyCreationService, projectTechnologyDeleteService, projectTechnologyUpdationService, UpdateBasicProjectInfoService, userAvailabilityListCalenderService, DoFileUploadService, GetProjectDocuments, DownloadFile, DeleteFile, tasksListService, allTasksListService, tasksListServiceFromDate, tasksHistoryListService } from "../services/projectDetails";
export const projectDetails = {
    state: {
        getDetailedProjectData: {},
        membersList: {},
        tasksList: {},
        tasksHistoryList: {},
        activeMembersList: {},
        userAvailabilityCalender: {},
        session: {},
        files: [],
        totalTasksCount:"",
        monthlyTasksCount:"",
        monthlyTasksCountFromDate:""


    },
    reducers: {
        getDetailedProjectResponseData: (state, data) => {
            return {
                ...state,
                getDetailedProjectData: data,
            };
        },
        getMembersListData: (state, data) => {
            return {
                ...state,
                membersList: data,
            };
        },
        getActiveMembersListData: (state, data) => {
            return {
                ...state,
                activeMembersList: data,
            };
        },
        getUserAvailabilityCalenderData: (state, data) => {
            return {
                ...state,
                userAvailabilityCalender: data,
            };
        },
        getSessionData: (state, data) => {
            return {
                ...state,
                session: data,
            };
        },
        getProjectDocumentData: (state, data) => {
            return {
                ...state,
                files: data.documents
            }
        },
        getTasksListData: (state, data) => {
            return {
                ...state,
                tasksList: data,
                totalTasksCount: data?.totalCount,
                monthlyTasksCount: data?.count,
                monthlyTasksCountFromDate: data?.monthlyCount
            };
        },
        getTasksHistoryData: (state, data) => {
            return {
                ...state,
                tasksHistoryList: data,
            };
        },
    },
    effects: (dispatch) => ({
        async getProjectDetailes(data) {
            try {
                const responsedata = await projectDetailService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getMembersList(data) {
            try {
                const responsedata = await membersListService(data);
                this.getMembersListData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getActiveMembersList(data) {
            try {
                const responsedata = await activeMembersListService(data);
                this.getActiveMembersListData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getUserAvailabilityCalender(data) {
            try {
                const responsedata = await userAvailabilityListCalenderService(data);
                this.getUserAvailabilityCalenderData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async createUserAvailabilityCalender(data) {
            try {
                const responsedata = await createuserAvailabilityListCalenderService(data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectStatusChange(data) {
            try {
                const responsedata = await projectStatusChangeService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectDescriptionChange(data) {
            try {
                const responsedata = await projectDescriptionChangeService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async getSession(data) {
            try {
                const responsedata = await getSessionService(data);
                this.getSessionData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRequirementCreation(data) {
            try {
                const responsedata = await projectRequirementCreationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRequirementNoteCreation(data) {
            try {
                const responsedata = await projectRequirementNoteCreationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRequirementUpdation(data) {
            try {
                const responsedata = await projectRequirementUpdationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRequirementNoteUpdation(data) {
            try {
                const responsedata = await projectRequirementNoteUpdationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectTechnologyCreation(data) {
            try {
                const responsedata = await projectTechnologyCreationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },


        async projectTechnologyUpdation(data) {
            try {
                const responsedata = await projectTechnologyUpdationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async projectRequirementDelete(data) {
            try {
                const responsedata = await projectRequirementDeleteService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async projectRequirementNoteDelete(data) {
            try {
                const responsedata = await projectRequirementNoteDeleteService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectTechnologyDelete(data) {
            try {
                const responsedata = await projectTechnologyDeleteService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRoadmapTaskcreation(data) {
            try {
                const responsedata = await projectRoadmapTaskCreationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async projectRoadmapIssuecreation(data) {
            try {
                const responsedata = await projectRoadmapIssueCreationService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async projectRoadmapIssuedelete(data) {
            try {
                const responsedata = await projectRoadmapIssueDeleteService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async projectRoadmapIssueupdate(data) {
            try {
                const responsedata = await projectRoadmapIssueUpdateService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },


        async projectRoadmapSprintDelete(data) {
            try {
                const responsedata = await projectRoadmapSprintDeleteService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async UpdateBasicProjectInfo(data) {
            try {
                const responsedata = await UpdateBasicProjectInfoService(data);
                this.getDetailedProjectResponseData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async updateProjectDetailsData(data) {
            try {
                this.getDetailedProjectResponseData(data);
            } catch (err) {
                throw err;
            }
        },
        async doFileUpload(data) {
            try {
                const responsedata = await DoFileUploadService(data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getProjectDocuments(data) {
            try {
                const responsedata = await GetProjectDocuments(data);
                this.getProjectDocumentData(responsedata.data)
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async downloadFile(data) {
            try {
                const responsedata = await DownloadFile(data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async deleteFile(data) {
            try {
                const responsedata = await DeleteFile(data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getTasksList(data) {
            try {
                const responsedata = await tasksListService(data);
                this.getTasksListData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async getAllTasksList(data) {
            try {
                const responsedata = await allTasksListService(data);
                this.getTasksListData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

        async getTasksListFromDate(data) {
            try {
                const responsedata = await tasksListServiceFromDate(data);
                this.getTasksListData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },
        async getTaskHistory(data) {
            try {
                const responsedata = await tasksHistoryListService(data);
                this.getTasksHistoryData(responsedata.data);
                return responsedata.data;
            } catch (err) {
                throw err;
            }
        },

    }),
}