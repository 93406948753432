import React from 'react';
import ChangePassword from '../../../components/ChangePassword';

const PasswordReset = () => {

    return (
        <>
          
            <div className="px-6 max-w-full overflow-x-auto">
                <div className="mx-auto w-full max-w-454">
                    <ChangePassword />
                </div>
            </div>

        </>
    )
}

export default PasswordReset
