import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ handlePageClick, pageCount, pageLimit,currentPage }) => {
    return (
        <div>
            {/* Pagination */}
            <ReactPaginate
                forcePage={currentPage}
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
                itemsPerPage={pageLimit}
                containerClassName="flex flex-wrap items-center justify-start md:justify-end my-4 custom-pagination-wrapper"
                pageClassName="mx-1 mb-2 text-sm w-8 h-8 border rounded hover:bg-primary-navyBlue hover:rounded hover:text-white transition duration-150 ease-out"
                pageLinkClassName="w-full h-full flex items-center justify-center"
                previousClassName="mx-1 mb-2 px-2 h-8 border rounded flex items-center hover:bg-primary-navyBlue hover:rounded hover:text-white transition duration-150 ease-out custom-nav"
                previousLinkClassName="text-sm bg-transparent"
                nextClassName="mx-1 mb-2 px-2 h-8 border rounded flex items-center hover:bg-primary-navyBlue hover:rounded hover:text-white transition duration-150 ease-out custom-nav"
                nextLinkClassName="text-sm bg-transparent"
                breakClassName="mx-1 w-8 h-8 border rounded mb-2 flex items-center"
                breakLinkClassName="px-2 h-8"
                activeClassName="py-2 bg-primary-navyBlue rounded border-primary-navyBlue text-white"
            />
        </div>
    )
}

export default Pagination