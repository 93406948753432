import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import Loader from '../Utilities/Loader';

export default function AddTechnology({ technologyData, addTechnology, techType, editData, closeModal }) {
    const [toggle, setToggle] = useState(false);
    const [techId, setTechId] = useState("");
    const [version, setVersion] = useState("");
    const [note, setNote] = useState("");
    const [reason, setReason] = useState("");
    const toggleClass = ' transform translate-x-5 transition delay-150 duration-150 ease-linear';
    const [isUpdateLoading, setUpdateLoading] = useState(false);

    const filterdTechnologyData = technologyData.filter(techitem => {
        if (techitem.type === techType) {
            return techitem;
        }
    });

    const submitData = async () => {
        try {
            let newtechnologyname = "";
            let technicalid = "";
            const filteredData = filterdTechnologyData.filter(item => {
                return item._id === techId.value
            });

            if (filteredData.length > 0) {
                technicalid = filteredData[0]._id;
                newtechnologyname = ""
            } else {
                newtechnologyname = techId.value;
                technicalid = ""
            }
            setUpdateLoading(true);
            const responseData = await addTechnology({ techId: technicalid, version, note, reason, newtechnologyname, status: toggle, platform: techType }, editData);
            setUpdateLoading(false);

        } catch (err) {
            setUpdateLoading(false);

        }
    };

    useEffect(() => {
        if (editData) {
            setToggle(editData.status);
            setTechId({ label: editData.techId.name, value: editData.techId._id });
            setVersion(editData.version);
            setNote(editData.note);
            setReason(editData.reason);
        } else {
            setToggle(true);
        }
    }, [editData]);


    return (
        <div>
            <div className="">
                <div className="text-black font-medium block mr-5 text-lg text-xl mb-6"> {techType === 'server' ? "Server" : "Client"} </div>

                <CreatableSelect
                    className="mb-7 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 custom-search-select custom-search-create"
                    name="client"
                    value={techId}
                    isSearchable={true}
                    isClearable={true}
                    classNamePrefix="dropdownSelect"
                    options={filterdTechnologyData.map((project) => ({ label: project.name, value: project._id }))}
                    placeholder="Technology Name"
                    onChange={(e) => setTechId(e)}
                />

                {/* <input type="text" name="" placeholder="Node" className="mb-7 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400" /> */}
                <input value={version} onChange={(evt) => {
                    setVersion(evt.target.value);
                }} type="text" name="" placeholder="Version" className="mb-7 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400" />
                <textarea placeholder="Description" value={note} onChange={(evt) => {
                    setNote(evt.target.value);
                }} className="mb-5 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-2 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 h-28 resize-none"></textarea>
                <div className="mb-6 text-sm flex items-center text-primary font-medium">Currently Using
                    <div onClick={() => { setToggle(!toggle); }} className={`flex items-center rounded-full p-1 cursor-pointer ml-4 md:w-10 md:h-5 w-10 h-5  ${toggle ? "bg-green-600" : "bg-gray-300"}`}>
                        <div className={`bg-white md:w-3 md:h-3 h-3 w-3 rounded-full shadow-md transform " +  ${toggle ? toggleClass : null}`}></div>
                    </div>
                </div>
                {/* {toggle === false ?
                    <input value={reason} onChange={(evt) => {
                        setReason(evt.target.value);
                    }} type="text" name="" placeholder="Reason" className="mb-7 focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400" />
                    : " "} */}
                <button
                    disabled={isUpdateLoading}
                    onClick={() => {
                        submitData();
                    }} className="w-full flex items-center justify-center font-title border h-12 border-primary-navyBlue bg-primary-navyBlue text-white text-sm font-semibold transition duration-300 ease-out hover:bg-opacity-80 px-4 rounded-md">
                    Submit
                    {isUpdateLoading ? <Loader /> : null}
                </button>

            </div>
        </div>
    )
}
