
import { Navigate, Route, Routes } from 'react-router-dom';
import Header from '../../components/shared/Header';
import Issues from '../../containers/pages/issues/Issues';
import Member from '../../containers/pages/members/Member';
import People from '../../containers/pages/people/People';
import PasswordReset from '../../containers/pages/profile/Passwordreset';
import Profile from '../../containers/pages/profile/Profile';
import Project from '../../containers/pages/projects/Project';
import ProjectDetails from '../../containers/pages/projects/ProjectDetails';



export function Developer() {
    // console.log('loged as user');
    return (
        <div>
            <Header />
            <div className="flex items-center justify-center h-[calc(100vh-80px)] text-primary-navyBlue">
                    <span>Developer account doesn't have view </span>
            </div>
            <Routes>
                { /* <Route path="/projects/:name" element={<ProjectDetails />} />
                <Route path="/projects" element={<Project />} />
                <Route path="/availability" element={<People />} />
                <Route path="/people" element={<Member />} />
                <Route path="/issues" element={<Issues />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<PasswordReset />} />
                <Route path="/" element={<Navigate to="/projects" />} />
                <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>
            {/* <Footer /> */}
        </div>)
}