import { loginService, tokenValidate, forgotPasswordService, resetPasswordService, profileUpdateService, changePasswordService } from "../services/auth";

export const auth = {
    state: {
        userInfo: {},
        isbootstrapped: false
    },
    reducers: {
        setUserInfo: (state, data) => {
            return {
                ...state,
                userInfo: data,
                userInfoData: data.userInfo,
                isbootstrapped: true
            };
        },
    },
    effects: (dispatch) => ({
        async loginUser(userdata) {
            try {
                const Responsedata = await loginService(userdata);
                localStorage.setItem('logininfo', JSON.stringify(Responsedata.data));
                this.setUserInfo(Responsedata.data);
                return Responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async forgotPassword(userdata) {
            try {
                const ResponseData = await forgotPasswordService(userdata);
                return ResponseData?.data;
            } catch (err) {
                console.log('err', err);
                throw err
            }
        },
        async resetPassword(userdata) {
            try {
                const ResponseData = await resetPasswordService(userdata);
                return ResponseData;
            } catch (err) {
                console.log('err', err);
                throw err
            }
        },
        async logOut(userdata) {
            try {
                localStorage.removeItem('logininfo');
                this.setUserInfo({});
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async validateToken() {
            try {
                const userInfoFromStorage = localStorage.getItem('logininfo');
                if (userInfoFromStorage) {
                    const userInfoData = JSON.parse(userInfoFromStorage);
                    if (userInfoData && userInfoData.token) {
                        const ResponseData = await tokenValidate({ token: userInfoData.token });
                        if (!ResponseData.data.istokenvalid) {
                            localStorage.removeItem('logininfo');
                            this.setUserInfo({});
                        } else {
                            this.setUserInfo(userInfoData);
                        }
                    } else {
                        dispatch.auth.setUserInfo({})
                    }
                } else {
                    dispatch.auth.setUserInfo({})
                }
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async profileUpdate(userdata) {
            try {
                const Responsedata = await profileUpdateService(userdata);
                const userInfoFromStorage = localStorage.getItem('logininfo');
                if (userInfoFromStorage && Responsedata && Responsedata.data && Responsedata.data.success) {
                    const userInfoData = JSON.parse(userInfoFromStorage);
                    const updatedData = { token: userInfoData.token, userInfo: Responsedata.data.updateddata };
                    localStorage.setItem('logininfo', JSON.stringify(updatedData));
                    this.setUserInfo(updatedData);
                }
                return Responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async changePassword(userdata) {
            try {
                const Responsedata = await changePasswordService(userdata);
                const userInfoFromStorage = localStorage.getItem('logininfo');
                if (userInfoFromStorage && Responsedata && Responsedata.data && Responsedata.data.success) {
                    const userInfoData = JSON.parse(userInfoFromStorage);
                    const updatedData = { token: userInfoData.token, userInfo: Responsedata.data.updateddata };
                    localStorage.setItem('logininfo', JSON.stringify(updatedData));
                    this.setUserInfo(updatedData);
                }
                return Responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

    }),
}

