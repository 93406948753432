import { API_ROUTES } from "../config";
import { http } from "../http"

export const listIssuesService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["all-issues"]}?search=${data?.search}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }

}