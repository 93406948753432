import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/Common/Pagination';
import moment from 'moment';
import DatePicker from "react-datepicker";

function BookedTime() {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [currentPage, SetCurrentPage] = useState(0);
    const bookedTime = useSelector((state) => state.time.bookedTime);
    const timeTotalCount = useSelector((state) => state.time.bookedTimeTotalCount);
    const [selectedFilter, setSelectedFilter] = useState(new Date())
    const [searchData, setSearchData] = useState("")
    const [focus, setFocus] = useState(false);
    const [dateFocus, setDateFocus] = useState(false);
    const pageLimit = 10;
    const pageCount = (Math.ceil(timeTotalCount / pageLimit));
    let timout = ""

    const headerTitle = [
        {
            id: '1',
            label: 'Name',
            classwidth: '1/3',
        },
        {
            id: '2',
            label: 'Project',
            classwidth: '1/2',
        },
        {
            id: '3',
            label: 'Time Booked',
            classwidth: '1/3',
        },
        {
            id: '4',
            label: 'TW-Billable',
            classwidth: '1/3'
        },
        {
            id: '5',
            label: 'TW-Non Billable',
            classwidth: '1/3'
        },
        {
            id: '6',
            label: 'TW-Total',
            classwidth: '1/3'
        },
        {
            id: '7',
            label: 'TW-Billed',
            classwidth: '1/3'
        }
    ]

    const GetDates = (value) => {
        var firstDay = moment(value).startOf('month').format("YYYY-MM-DD")
        var lastDay = moment(value).endOf('month').format("YYYY-MM-DD")
        return { from: firstDay, to: lastDay }
    }
    const getBookedTime = async () => {
        try {
            setLoading(true);
            const dateFilter = GetDates(new Date())
            const res = await dispatch.time.getBookedTime({
                limit: pageLimit,
                currentPage: currentPage,
                search: searchData,
                ...dateFilter
            });
            setLoading(false);
        } catch (err) {
            console.log("err", err)
        }
    }
    // For Pagination
    const handlePageClick = async (event) => {
        setLoading(true);
        SetCurrentPage(event.selected);
        const dateFilter = GetDates(selectedFilter)
        await dispatch.time.getBookedTime({
            limit: pageLimit,
            currentPage: event.selected,
            search: searchData,
            ...dateFilter
        })
        setLoading(false);
    };
    //For Search
    const searchTime = async (search) => {
        clearTimeout(timout)
        setLoading(true);
        timout = setTimeout(async function () {
            setSearchData(search)
            const dateFilter = GetDates(selectedFilter)
            await dispatch.time.getBookedTime({
                limit: pageLimit,
                currentPage: currentPage,
                search: search,
                ...dateFilter
            })
            setLoading(false);
        }, 2000)
    }
    //For Month Filter
    const MonthFilter = async (value) => {

        if (value != null) {
            setLoading(true);
            setSelectedFilter(moment(value).toDate())
            const dateFilter = GetDates(value)
            await dispatch.time.getBookedTime({
                limit: pageLimit,
                currentPage: currentPage,
                search: searchData,
                ...dateFilter
            })
            setLoading(false);
        }
    }
    useEffect(() => {
        getBookedTime();
    }, []);
    return (
        <>
            <div className="w-full">
                <div className="px-6 pt-6">
                    <div className="border-b md:flex md:justify-between pb-6">

                        <div className="mb-3 sm:mb-0">
                            <h3 className="font-title text-xl text-title font-medium">Time Comparison</h3>
                        </div>

                        <div className='md:flex md:justify-between sm:space-y-2 md:space-y-0 md:space-x-4 w-full sm:w-auto'>

                            <div className={`flex items-center space-x-2 border border-gray-200 px-2 rounded-md ${focus ? 'border-primary-navyBlue transition delay-150 duration-150' : null}`}>
                                <div>
                                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z" fill="#54585D" />
                                    </svg>
                                </div>
                                <input className="text-xs w-56 outline-none placeholder-secondary-grey-300 text-secondary-grey-300 h-8" type="text" placeholder="Search Name, Project"
                                    onChange={(evt) => { SetCurrentPage(0); searchTime(evt.target.value) }}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)} />
                            </div>

                            <div className="custom-select-wrapper w-full sm:w-auto h-6 mt-5 md:mt-0">
                                <div className={`flex items-center justify-between rounded-md border border-gray-200 ${dateFocus ? 'border-primary-navyBlue transition delay-150 duration-150' : null} `}>
                                    <DatePicker className="custom-datepicker-wrapper border-none  relative appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-xs border rounded-md px-4 pr-8 w-56 placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400 ring-transparent ring-0 h-8"
                                        onChange={MonthFilter}
                                        selected={selectedFilter}
                                        dateFormat="MMMM/yyyy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        shouldCloseOnSelect={true}
                                        onFocus={() => setDateFocus(true)}
                                        onBlur={() => setDateFocus(false)}
                                    />

                                    <span className="cursor-pointer relative right-3 md:right-6">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.04708 0.0437498C2.90958 0.106249 2.78771 0.231247 2.71897 0.381245C2.66272 0.503119 2.65647 0.590617 2.65647 1.25623V1.99997H2.16899C1.59714 1.99997 1.41902 2.03747 1.04716 2.2281C0.709672 2.40309 0.403435 2.70934 0.228442 3.04684C-0.0184232 3.52495 0.000326034 3.05309 0.000326034 8.99988C0.000326034 14.9467 -0.0184232 14.4748 0.228442 14.9529C0.403435 15.2904 0.709672 15.5967 1.05028 15.7748C1.51901 16.0154 0.972161 15.9998 8 15.9998C15.0278 15.9998 14.481 16.0154 14.9497 15.7748C15.2903 15.5967 15.5966 15.2904 15.7716 14.9529C16.0184 14.4748 15.9997 14.9467 15.9997 8.99988C15.9997 3.05309 16.0184 3.52495 15.7716 3.04684C15.5966 2.70934 15.2903 2.40309 14.9528 2.2281C14.581 2.03747 14.4029 1.99997 13.831 1.99997H13.3435V1.25623C13.3435 0.521869 13.3435 0.509369 13.2654 0.353121C13.2154 0.253121 13.1373 0.159373 13.056 0.106249C12.9279 0.015625 12.9248 0.015625 12.3717 0.00625038C11.7592 -0.00625038 11.678 0.0124998 11.4936 0.209373C11.3342 0.37812 11.3124 0.503119 11.3124 1.28436V1.99997H8H4.68763V1.29373C4.68763 0.521869 4.67201 0.412495 4.52514 0.234372C4.35015 0.0249996 4.25953 0 3.6658 0C3.30019 0.00312519 3.1127 0.015625 3.04708 0.0437498ZM14.6497 10.4436L14.6404 14.1717L14.5529 14.3186C14.4966 14.4154 14.4154 14.4967 14.3185 14.5529L14.1716 14.6404H8H1.82838L1.68151 14.5529C1.58464 14.4967 1.50339 14.4154 1.44714 14.3186L1.35965 14.1717L1.35027 10.4436L1.34402 6.71866H8H14.656L14.6497 10.4436Z" fill="#54585D">
                                            </path>
                                        </svg>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-6 max-w-full overflow-x-auto">
                <div className="bg-white rounded flex flex-col min-w-[768px]">
                    <header className="border-b">
                        <div className="flex items-center justify-between py-3 px-3">
                            {headerTitle && headerTitle.map((item) =>
                                <div key={`header_${item.id}`} className={`text-sm text-primary-400 basis-${item.classwidth} ${item.center}`}>{item.label}</div>
                            )}
                        </div>
                    </header>
                    <>
                        {
                            !loading && bookedTime?.length > 0 ?
                                bookedTime?.map((item, index) => {
                                    return (<div key={`body_${item.id}`} className="flex items-center justify-between py-3 hover:bg-primary-light transition duration-150 ease-out px-3 border-b">
                                        <div className="flex justify-start text-sm text-title capitalize basis-1/3 break-all ">
                                            {item['first_name'] ? item['first_name'] : '-'}&nbsp;{item['last_name'] && item['last_name']}
                                        </div>
                                        <div className="flex flex-col justify-start basis-1/2 break-all pr-3 sm:pr-0">
                                            <div className="mb-1 text-title text-sm font-normal flex items-start capitalize">
                                                {item.project_name && item.project_name}
                                            </div>
                                        </div>
                                        <div className="flex justify-start text-sm text-title  basis-1/3 break-all ">
                                            {item['totalHours'] && `${item['totalHours']} hrs`}
                                        </div>
                                        <div className="flex justify-start text-sm text-title  basis-1/3 break-all ">
                                            {Number(item['billable_hours_sum']) ? item['billable_hours_sum'] : 0} hrs
                                        </div>
                                        <div className="flex justify-start text-sm text-title  basis-1/3 break-all ">
                                            {Number(item["non_billable_hours_sum"]) ? item["non_billable_hours_sum"] : 0} hrs
                                        </div>
                                        <div className="flex justify-start text-sm text-title  basis-1/3 break-all ">
                                            {
                                                 Number(item["billable_hours_sum"]) && Number(item["non_billable_hours_sum"]) ?
                                                 Number(item["billable_hours_sum"]) + Number(item["non_billable_hours_sum"])
                                                 :0 
                                            } hrs
                                        </div>
                                        <div className="flex justify-start text-sm text-title  basis-1/3 break-all">
                                            {Number(item["billed_hours_sum"]) ? item["billed_hours_sum"] : 0} hrs
                                        </div>
                                    </div>)
                                })
                                :
                                <div className="flex justify-center items-center h-28">
                                    <p className="text-sm text-title">{`${loading ? 'Loading...' : 'No data'}`}</p>
                                </div>
                        }


                    </>
                </div>
                {
                    (timeTotalCount && timeTotalCount > 10)
                        ?
                        <Pagination handlePageClick={handlePageClick} pageCount={pageCount} pageLimit={pageLimit} currentPage={currentPage} />
                        :
                        ""
                }
            </div>
        </>
    )
}

export default BookedTime