import { API_ROUTES } from "../config";
import { http } from "../http"

export const listProjectsService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES.projects}`, { params: { page: data?.currentPage, limit: data?.limit, status: data?.status, search: data?.search } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const ProjectTitleSearchService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["project-name-search"]}`, { params: { search: data?.search } });
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }

}





export const CreateProjectsService = async (data) => {
    try {
        const responseData = await http().post(API_ROUTES["project-create"], data);
        return responseData;
    } catch (err) {
        // console.log('err', err);
        throw err;

    }
}

export const ClientNameSearch = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["project-client"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }

}

export const TechnologyNameSearch = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["project-technology"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const SearchUserService = async (data) => {
    try {
        const responseData = await http().get(`${API_ROUTES["search-user"]}/${data}`);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }

}

export const InviteMemberService = async (data) => {
    try {
        const responseData = await http().post(API_ROUTES["invite-user"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }

}


export const designationSearch = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["designation"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}


export const rolesSearch = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["roles"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const skillsSearch = async (data) => {
    try {
        const responseData = await http().get(API_ROUTES["skills"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err;

    }
}

export const register = async (data) => {
    try {
        const responseData = await http().post(API_ROUTES["register"], data);
        return responseData;
    } catch (err) {
        console.log('err', err);
        throw err

    }
}