import { CreateProjectsService, listProjectsService, ClientNameSearch, TechnologyNameSearch, SearchUserService, rolesSearch, InviteMemberService, designationSearch, skillsSearch, register, ProjectTitleSearchService } from "../services/projects";

export const projects = {
    state: {
        projects: [],
        createProjectResponse: {},
        projectClient: [],
        technology: [],
        designation: [],
        roles: [],
        skills: [],
    },

    reducers: {
        listProjects: (state, data) => {
            return {
                ...state,
                projects: data,
                projectsTotal: data?.totalcount,
            };
        },
        createProjectsResponseData: (state, data) => {
            return {
                ...state,
                createProjectResponse: data
            };
        },
        clientProjectsResponseData: (state, data) => {
            return {
                ...state,
                projectClient: data
            };
        },
        technologyResponseData: (state, data) => {
            return {
                ...state,
                technology: data
            };
        },
        searchUserResponseData: (state, data) => {
            return {
                ...state,
                searchUserResponseData: data
            };
        },
        inviteMemberResponseData: (state, data) => {
            return {
                ...state,
                inviteMemberResponseData: data
            };
        },
        designationResponseData: (state, data) => {
            return {
                ...state,
                designation: data
            };
        },

        rolesResponseData: (state, data) => {
            return {
                ...state,
                roles: data
            };
        },

        skillsResponseData: (state, data) => {
            return {
                ...state,
                skills: data
            };
        },

    },
    effects: (dispatch) => ({
        async listProjectData(data) {
            try {
                const responsedata = await listProjectsService(data);
                this.listProjects(responsedata.data);
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async createProject(data) {
            try {
                const responsedata = await CreateProjectsService(data);
                this.createProjectsResponseData(responsedata.data);
                return responsedata?.data;
            } catch (err) {
                //console.log('err', err);
                throw err;
            }
        },

        async clientNameSearch(data) {
            try {
                const Responsedata = await ClientNameSearch(data);
                this.clientProjectsResponseData(Responsedata.data);
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async searchUserData(data) {
            try {
                const responsedata = await SearchUserService(data);
                this.searchUserResponseData(responsedata.data);
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },
        async inviteMember(data) {
            try {
                const responsedata = await InviteMemberService(data);
                this.inviteMemberResponseData(responsedata.data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async technologyNameSearch(data) {
            try {
                const Responsedata = await TechnologyNameSearch(data);
                this.technologyResponseData(Responsedata.data);
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async designationSearch(data) {
            try {
                const Responsedata = await designationSearch(data);
                this.designationResponseData(Responsedata.data.data);
                return Responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async rolesSearch(data) {
            try {
                const Responsedata = await rolesSearch(data);
                this.rolesResponseData(Responsedata.data.data);
                return Responsedata?.data;

            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async skillsSearch(data) {
            try {
                const Responsedata = await skillsSearch(data);
                this.skillsResponseData(Responsedata.data.data);
                return Responsedata?.data;

            } catch (err) {
                console.log('err', err);
                throw err;

            }
        },

        async register(data) {
            try {
                const responsedata = await register(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err

            }
        },

        async ProjectTitleSearch(data) {
            try {
                const responsedata = await ProjectTitleSearchService(data);
                return responsedata?.data;
            } catch (err) {
                console.log('err', err);
                throw err

            }
        },
    }),
}