import React from 'react';
import { ToastContainer } from 'react-toastify';

const Toaster = () => {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                closeOnClick={true}
                rtl={false}
                draggable
                pauseOnHover
            />
        </>
    )
}

export default Toaster