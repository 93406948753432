import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalLayout from '../../../components/Common/ModalLayout';
import Pagination from '../../../components/Common/Pagination';
import AddMember from '../people/AddMember';

const Member = () => {
    const [loading, setLoading] = useState(false);
    const [open, SetOpenModal] = useState(false);
    const [memberEditData, SetMemberEditData] = useState(null);
    const [memberSearchData, setMemberSearchData] = useState("");
    const dispatch = useDispatch();
    const peopleData = useSelector((state) => state.people.people.data);
    const peopleTotal = useSelector((state) => state.people.peopleTotal);
    const peopleLimit = useSelector((state) => state.people.peopleLimit);
    const [currentPage, SetCurrentPage] = useState(0);

    const pageLimit = 10;
    const pageCount = (Math.ceil(peopleTotal / pageLimit));

    const headerTitle = [
        {
            id: '1',
            label: 'Name',
            classwidth: '1/3',
        },
        {
            id: '2',
            label: 'Email',
            classwidth: '1/3',
        },
        {
            id: '3',
            label: 'Designation',
            classwidth: '1/3',
        },
        {
            id: '4',
            label: 'Skills',
            classwidth: '1/2',
        },
        {
            id: '5',
            label: 'Application Role',
            classwidth: '1/3',
        },
        {
            id: '7',
            label: 'Action',
            classwidth: '1/4',
            center: 'text-center'
        },
    ]

    const getPeopleListData = async () => {
        setLoading(true);
        await dispatch.people.listPeopleData({
            limit: pageLimit,
            currentPage: currentPage,
            search: memberSearchData
        });
        setLoading(false);
    }

    useEffect(() => {
        getPeopleListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberSearchData]);

    // For Pagination
    const handlePageClick = async (event) => {
        setLoading(true);
        SetCurrentPage(event.selected);
        await dispatch.people.listPeopleData({
            limit: peopleLimit,
            currentPage: event.selected,
            search: memberSearchData

        })
        setLoading(false);
    };

    const handleModal = () => {
        SetOpenModal(!open);
    }

    useEffect(() => {
        if (!open) {
            SetMemberEditData(null);
        }

    }, [open]);


    const onSuccess = () =>{
        getPeopleListData();
    }


    return (
        <>
            <div className="w-full">
                <div className="px-6 pt-6">
                    <div className="border-b flex flex-wrap items-center justify-between pb-6">
                        <div className="mb-3 sm:mb-0">
                            <h3 className="font-title text-xl text-title font-medium">All Members</h3>
                        </div>
                        
                        <div className="flex sm:flex-wrap justify-between items-center w-full sm:w-auto">
                           
                            <div className="mr-2 flex w-full -ml-4 sm:ml-0 sm:w-auto">
                                <div className="flex justify-center items-center relative left-6">
                                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z" fill="#54585D" />
                                    </svg>
                                </div>
                                <input className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear  border rounded-md text-xs px-6  w-full placeholder-secondary-grey-300 text-secondary-grey-300 border-secondary-grey-400 h-8" type="text" onChange={(evt) => {
                                    SetCurrentPage(0);
                                    setMemberSearchData(evt.target.value);
                                }} />
                            </div>
                            
                            <div className="flex items-center w-[50%] sm:w-auto">
                                <button onClick={() => handleModal()} className="font-title flex items-center bg-primary-navyBlue text-white text-[10px] sm:text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-4 rounded-md">
                                    <span className="text-base sm:text-xl text-white -mt-[3px] flex mr-1">+</span>Add Member</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="px-6 max-w-full overflow-x-auto">
                <div className="bg-white rounded flex flex-col min-w-[768px]">
                    <header className="border-b">
                        <div className="flex items-center justify-between py-3 px-3">
                            {headerTitle && headerTitle.map((item) =>
                                <div key={item.id} className={`text-sm text-primary-400 basis-${item.classwidth} ${item.center}`}>{item.label}</div>
                            )}
                        </div>
                    </header>
                    <>
                        {/* People listing */}
                        {!loading && peopleData?.length > 0 ?
                            peopleData.map((item, index) =>{
                                let roleitems = item && item.roles && item.roles.length > 0 && item.roles.map((roleitem, ind) => {
                                    return roleitem.name;
                                }).join(', ');
                                    // <Link to={`/projects/`} key={index} className="flex items-center justify-between py-3 hover:bg-primary-light transition duration-150 ease-out px-3 border-b">
                               return  (<div className="flex items-center justify-between py-3 hover:bg-primary-light transition duration-150 ease-out px-3 border-b" key={index}>
                                    <div className="flex flex-col justify-start basis-1/3 break-all pr-3">
                                        <div className="mb-1 text-title text-xs sm:text-[13px] font-normal flex items-start capitalize">
                                            {item.first_name?.length > 0 ? item.first_name : '-'}&nbsp;{item.last_name?.length > 0 && item.last_name}
                                        </div>
                                    </div>
                                    <div className="flex justify-start text-xs sm:text-[13px] text-title capitalize basis-1/3 break-all pr-4">
                                        {item && item.email}
                                    </div>
                                    <div className="flex justify-start text-xs sm:text-[13px] text-title capitalize basis-1/3 break-all pr-4">
                                        {item.designation?.name.length > 0 && item.designation.name}
                                    </div>
                                    <div style={{}} className="flex flex-wrap items-start justify-start text-xs sm:text-[13px] text-title capitalize basis-1/2  pr-4 break-words">
                                        {item && item.skills && item.skills.length > 0 && item.skills.map((skillitem, ind) => {
                                            return (
                                                <div key={ind} className='mb-4 lg:mb-0'>
                                                    <span className="px-2 py-1 border rounded text-xs sm:text-[13px] mr-1">{skillitem.name}</span> {(ind + 1) === item.roles.length ? '' : '  '}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    
                                    <div className="flex justify-start text-xs sm:text-[13px] text-title basis-1/3 pr-4 break-all">
                                        {
                                        
                                        roleitems
                                        
                                        }

                                    </div>

                                    <div onClick={() => { handleModal(); SetMemberEditData(item); }} className="flex justify-start text-sm text-title basis-1/4 break-all justify-center">
                                        <span className="inline-block cursor-pointer font-title flex items-center bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-7 px-2 rounded-md">
                                            <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_43_331)">
                                                    <path d="M4.15799 13.1623L0.00115967 14L0.839993 9.84375L4.15799 13.1623ZM1.66483 9.01833L4.98283 12.3369L14.0012 3.31858L10.6826 0L1.66483 9.01833Z" fill="white" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_43_331">
                                                        <rect width="14" height="14" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </div>
                                </div>)
                                }
                            )
                            :
                            <div className="flex justify-center items-center h-28">
                                <p className="text-sm text-title">{`${loading ? 'Loading...' : 'No data'}`}</p>
                            </div>
                        }
                    </>
                </div>
                {/* Pagination */}
                {peopleTotal && peopleTotal > 10 && <Pagination handlePageClick={handlePageClick} pageCount={pageCount} pageLimit={pageLimit} currentPage={currentPage} />}
            </div>

            <ModalLayout closeModal={handleModal} open={open}>
                <AddMember onSuccess={onSuccess} memberEditData={memberEditData} closeModal={handleModal} />
            </ModalLayout>

        </>
    )
}

export default Member
