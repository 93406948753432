import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


const Issues = ({ issuesData, listissuesData }) => {

    const [search, setSearch] = useState("");

    useEffect(() => {
        listissuesData({ search });
    }, [search]);

    console.log('search', search);

    return (
        <>
            <div className="px-6 max-w-full overflow-x-auto">
                <div className="flex flex-wrap items-center justify-between mt-5 mb-5">
                    <div className="mb-3 sm:mb-0">
                        <h3 className="font-title text-xl text-black text-title font-medium font-semibold">Issues</h3>
                    </div>
                    <div className="flex">
                        <div className="flex flex-wrap items-center w-full sm:w-auto custom-search-select relative">
                            <input onChange={(evt) => setSearch(evt.target.value)} type="text" placeholder='Search Issues' className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear  border rounded-md text-xs  px-6  w-full placeholder-secondary-grey-300 text-secondary-grey-300 border-secondary-grey-400 h-8" />
                            <div className="absolute top-2/4 -translate-y-2/4 left-2 z-10"><svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z" fill="#54585D"></path></svg></div>
                        </div>
                        <button className="ml-4 font-title flex items-center bg-primary-navyBlue text-white text-xs transition duration-300 ease-out hover:bg-opacity-80 h-8 px-4 rounded-md">
                            <span className="text-xl text-white -mt-[3px] flex mr-1">+</span>
                            Add Issue</button>
                    </div>
                   
                </div>

                <div className="bg-white rounded flex flex-col">
                    <div className="pb-7">
                        <div className="pb-3">
                            <span className="mr-3 text-sm font-semibold text-black cursor-pointer">Latest</span>
                            <span className="text-sm font-medium cursor-pointer">Top</span>
                        </div>
                        <div className="flex flex-wrap items-center w-full sm:w-auto custom-search-select relative ">
                            <input onChange={(evt) => setSearch(evt.target.value)} type="text" placeholder='Search Issues' className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear  border rounded-md text-xs  px-7  w-full placeholder-secondary-grey-300 text-secondary-grey-300 border-secondary-grey-400 h-11" />
                            <div className="absolute top-2/4 -translate-y-2/4 left-2 z-10"><svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z" fill="#54585D"></path></svg></div>
                        </div>
                    </div>
                    {
                        issuesData && issuesData.data && issuesData.data.length > 0 &&
                        issuesData.data.map((projectitem, proind) => {
                            const issuesdata = projectitem.issues.map((issueitem, issueind) => {
                                return (<div key={issueind} className="mb-3 border-b pb-3" >
                                    <div className=""><h5 className="text-sm font-bold mb-1 text-primary-navyBlue">Title</h5>
                                        <p className="block text-xs text-black font-normal ">{issueitem.title}</p>

                                        <div className="flex mt-2 text-xs items-center">
                                            <span className="border text-xs items-center p-1 px-2 font-medium mr-2">Developers</span>
                                            2 answers
                                        </div>
                                    </div>
                                    {/* <div>
                                        <h5 className="text-xs font-bold  text-secondary-grey-200 mb-1">Answer</h5>
                                        <p className="block text-sm  text-black">{issueitem.answer}</p>
                                    </div> */}
                                </div>
                                )
                            });

                            return projectitem.issues.length > 0 &&
                                (<div key={proind} className="">
                                    {/* <h5 className="text-lg font-bold mb-6 capitalize text-black">{projectitem.name}</h5> */}
                                    {issuesdata}
                                </div>)

                        })
                    }

                </div>
            </div>
        </>
    );
}

const mapState = (state) => ({
    issuesData: state.issues.issues,
});

const mapDispatch = (dispatch) => ({
    listissuesData: dispatch.issues.listIssues,
});

export default connect(mapState, mapDispatch)(Issues)