import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import Loader from '../../../components/Utilities/Loader';
import DatePicker from "react-datepicker";

const Profile = () => {
    const dispatch = useDispatch();
    const [isUpdateLoading, setUpdateLoading] = useState(false);

    const userInfo = useSelector((state) => state.auth.userInfo);

    return (
        <div className="flex flex-col lg:flex-row myprofile-wrapper">
                <div className="flex flex-col  m-6 mt-0 md:m-0 lg:w-80 ">
                    <div className="p-7 py-24 left-0 top-0 h-full fixed  lg:w-80 border border-right">
                        <h6>Settings</h6>
                        <ul>
                            <li>My details</li>
                            <li>Profile</li>
                            <li>Password</li>
                        </ul>
                    </div>
                 
                </div>
            <div className="bg-white rounded mb-5 lg:mb-0  md:w-[calc(100%_-_320px)]  w-full lg:mr-5  p-8">

                <div className="md:w-[620px]">
                        <div className="mb-3 sm:mb-0">
                            <h3 className="font-title text-xl font-semibold text-black mb-3">Profile</h3>
                        </div>
                        <div className="bg-white rounded flex flex-col">
                    <Formik
                        initialValues={{
                            first_name: (userInfo && userInfo.userInfo && userInfo.userInfo.first_name) || "",
                            last_name: (userInfo && userInfo.userInfo && userInfo.userInfo.last_name) || "",
                            email: (userInfo && userInfo.userInfo && userInfo.userInfo.email) || "",
                        }}
                        validationSchema={Yup.object().shape({
                            first_name: Yup.string().required("Enter First Name"),
                            last_name: Yup.string().required("Enter Last Name"),
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        })}

                        onSubmit={async (values, { resetForm }) => {
                            try {
                                setUpdateLoading(true);
                                const responseData = await dispatch.auth.profileUpdate(values);
                                if (responseData.success) {
                                    toast.success(responseData.message);
                                } else {
                                    toast.error(responseData.message);
                                }
                                setUpdateLoading(false);

                            } catch (err) {
                                toast.error(err.error);
                                setUpdateLoading(false);
                            }

                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} className="w-full">
                                <div className="mb-8 relative grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="font-semibold text-tiny mb-1 inline-block">First Name</label>
                                        <Field
                                            className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                            placeholder="First Name"
                                            type="text"
                                            name="first_name"
                                            onChange={handleChange}
                                            value={values.first_name}
                                        />
                                        {errors.first_name && touched.first_name && (
                                            <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                                                {errors.first_name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="relative">
                                    <label className="font-semibold text-tiny mb-1 inline-block">Last Name</label>
                                        <Field
                                            className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                            placeholder="Last Name"
                                            type="text"
                                            name="last_name"
                                        />
                                        {errors.last_name && touched.last_name && (
                                            <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                                                {errors.last_name}
                                            </span>
                                        )}
                                    </div>
                                </div>


                                <div className="mb-8 relative grid grid-cols-2 gap-4">
                                <div className="relative ">
                                <label className="font-semibold text-tiny mb-1 inline-block">Email Address</label>
                                    <Field
                                        className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                        placeholder="Login Email"
                                        type="text"
                                        name="email"

                                    />
                                    {errors.email && touched.email && (
                                        <span className="text-red-500 text-xs absolute -bottom-5 left-0">
                                            {errors.email}
                                        </span>
                                    )}
                                </div>
                                <div className="relative ">
                                <label className="font-semibold text-tiny mb-1 inline-block">Role</label>
                                    <Field
                                        className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                                        placeholder="Enter job title"
                                        type="text"
                                        name="role"

                                    />                                   
                                </div>

                                </div>
                                        

                                <div className="mb-8 relative grid grid-cols-2 gap-4">
                                    <div className="relative ">
                                     <label className="font-semibold text-tiny mb-1 inline-block">Applying for Leave</label>
                                     
                                    <label className="relative ">
                      <DatePicker className="success appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                        //onChange={onChange}
                        //startDate={startDate}
                        dateFormat="dd-MM-yyyy"
                        //endDate={endDate}
                        selectsRange
                        //filterDate={isWeekday}
                      />
                      <span className="cursor-pointer absolute top-1/2 right-3 transform -translate-y-1/2"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.04708 0.0437498C2.90958 0.106249 2.78771 0.231247 2.71897 0.381245C2.66272 0.503119 2.65647 0.590617 2.65647 1.25623V1.99997H2.16899C1.59714 1.99997 1.41902 2.03747 1.04716 2.2281C0.709672 2.40309 0.403435 2.70934 0.228442 3.04684C-0.0184232 3.52495 0.000326034 3.05309 0.000326034 8.99988C0.000326034 14.9467 -0.0184232 14.4748 0.228442 14.9529C0.403435 15.2904 0.709672 15.5967 1.05028 15.7748C1.51901 16.0154 0.972161 15.9998 8 15.9998C15.0278 15.9998 14.481 16.0154 14.9497 15.7748C15.2903 15.5967 15.5966 15.2904 15.7716 14.9529C16.0184 14.4748 15.9997 14.9467 15.9997 8.99988C15.9997 3.05309 16.0184 3.52495 15.7716 3.04684C15.5966 2.70934 15.2903 2.40309 14.9528 2.2281C14.581 2.03747 14.4029 1.99997 13.831 1.99997H13.3435V1.25623C13.3435 0.521869 13.3435 0.509369 13.2654 0.353121C13.2154 0.253121 13.1373 0.159373 13.056 0.106249C12.9279 0.015625 12.9248 0.015625 12.3717 0.00625038C11.7592 -0.00625038 11.678 0.0124998 11.4936 0.209373C11.3342 0.37812 11.3124 0.503119 11.3124 1.28436V1.99997H8H4.68763V1.29373C4.68763 0.521869 4.67201 0.412495 4.52514 0.234372C4.35015 0.0249996 4.25953 0 3.6658 0C3.30019 0.00312519 3.1127 0.015625 3.04708 0.0437498ZM14.6497 10.4436L14.6404 14.1717L14.5529 14.3186C14.4966 14.4154 14.4154 14.4967 14.3185 14.5529L14.1716 14.6404H8H1.82838L1.68151 14.5529C1.58464 14.4967 1.50339 14.4154 1.44714 14.3186L1.35965 14.1717L1.35027 10.4436L1.34402 6.71866H8H14.656L14.6497 10.4436Z" fill="#54585D"></path></svg></span>
                    </label>
                                    </div>
                                </div>        


                                <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="p-3 px-4 bg-primary-navyBlue hover:bg-opacity-75 transition delay-150 duration-150 ease-linear text-white rounded-md text-sm font-semibold font-title"
                                >
                                    Submit
                                    {isUpdateLoading ? <Loader /> : null}

                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>




                </div>
            {/* <div className="mt-5">
                <div className="pt-6">
                    <div className="flex flex-wrap items-center justify-between pb-6">
                    
                    </div>
                </div>
            </div> */}

          
            </div>
           


           

        </div>
    )
}

export default Profile
