import axios from "axios";
import { API_BASE_PATH } from "../config";

export const http = () => {
    let instance;
    const userInfoFromStorage = localStorage.getItem('logininfo');
    if (userInfoFromStorage) {
        const userInfoData = JSON.parse(userInfoFromStorage);
        instance = axios.create({
            baseURL: API_BASE_PATH,
            headers: { 'Authorization': userInfoData.token }
        });
    } else {
        instance = axios.create({
            baseURL: API_BASE_PATH,
        });
    }

    instance.interceptors.request.use(function (config) {
        //console.log('interceptors reqest');
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    instance.interceptors.response.use(function (response) {
        //console.log('interceptors response');
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
            localStorage.removeItem('logininfo');
            window.location.reload();
        }
        if (error.response.status === 500) {
            throw { error: error.response.data.error }
            //throw new Error({ error: error.response.data.error });
        }

        return Promise.reject(error);
    });

    return instance;

};