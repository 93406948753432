import React,{useState} from 'react'
import Accordion from '../Common/Accordian';

export default function Issues(DetailedProjectData) {
    
    const [activeIndex, setActiveIndex] = useState(0);
    const issuesData = DetailedProjectData && DetailedProjectData.data &&  DetailedProjectData.data.project &&
    DetailedProjectData.data.project.issues;

  return (
      <div className='flex flex-col'>
        {  issuesData && issuesData.length  ? issuesData.map((item,key) =>
          <Accordion 
            title={item.title}
            index={key}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          >   {item && item.answer}  
          </Accordion>
        ): "No data"
        }

      </div>
  );
};